@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";


.desktop-list{
    .tabular-view ul.list-items li.item ul.item-row li{
        text-transform: none !important;
    }
}

.application-mobile-list-card {
    .card {
        .card-footer {
            margin: 0;
            .card-footer-group {
                .card-footer-value {
                    .status {
                        width: 180px;
                    }
                }
            }
        }
    }
}
.range {
    span {
        background: none !important;
    }
}
.application-list {
    padding: 0 15px;

    .application-list-header {
        padding-bottom: 25px;

        span {
            display: inline-block;
            cursor: pointer;

            &.filter-icon {
                img {
                    width: 21px;
                    height: 20px;
                }
            }
        }

        @media (max-width: 768px) {
            display: flex;
            justify-content: space-between;
        }
    }

    .application-filter {
        li {
            .position-date-range {
                input {
                    border: 0;
                    border-bottom: 1px solid $grey;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .application-mobile-list {
        .application-list-header {
            padding: 12px 0 0 0;
        }

        .card-header {
            position: relative;

            .card-header-group {
                .card-header-value {
                    .status {
                        position: absolute;
                        top: 0;
                        right: 0;

                        span {
                            border-top-left-radius: 0;
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 12px;
                        }
                    }
                }
            }
        }
    }

    .title-referral {
        display: flex;
        align-items: center;

        .title {
            padding-right: 9px;
        }
    }
}

@media (max-width: 585px) {
    .application-mobile-list-card {
        .card {
            .card-footer {
                flex-direction: column;
                align-items: flex-start;
                .card-footer-group {
                    .card-footer-value {
                        .status {
                            text-align: left;
                            margin-left: 0;
                            padding-top: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 358px) {
    .application-mobile-list-card {
        .card {
            .card-footer {
                .card-footer-group {
                    display: block;
                }
            }
        }
    }
}

.main {
    .mobile-filter {
        .filters {
            .selected-filter {
                padding-top: 5px !important;
                .non-selected-label {
                    width: 98%;
                }
                .experience-input {
                    margin: 0px !important;
                }
                .checkboxes .form-check {
                    padding: 15px 0 0;

                    @media screen and(max-width: 375px) {
                        padding: 12px 0 0;
                    }
                }
                .vertical-radio-buttons .form-check {
                    .d-block {
                        padding-bottom: 15px;
                        @media screen and(max-width: 375px) {
                            padding-bottom: 12px;
                        }
                    }
                }
            }
        }
    }
}
