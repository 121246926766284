@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.app-header-mobile {
    padding-left: 20px;
    box-shadow: 0px 0px 25.81px 3.19px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $blue;
    @include prefix(box-shadow, 0px 0px 25.81px 3.19px rgba(0, 0, 0, 0.4));
    // background: -webkit-gradient(90deg, #00539d 70%, #429ceb 140%);
    // background: -moz-gradient(90deg, #00539d 70%, #429ceb 140%);
    // background: -o-gradient(90deg, #00539d 70%, #429ceb 140%);
    // background: -ms-gradient(90deg, #00539d 70%, #429ceb 140%);
    // background: linear-gradient(90deg, #00539d 70%, #429ceb 140%);

    .menubar {
        cursor: pointer;
        display: flex;
        align-items: center;
        
        img {
            width: 60%;
        }

        h1 {
            padding: 12px 20px;
            // border-right: 1px solid $blue-green;

            img {
                width: 100%;
                max-height: 101px;
            }
        }
    }

    .vendor-product-logo {
        display: flex;
        align-items: center;

        .vendor-logo {
            padding: 8px 20px;

            div {
                background-color: #fff;
                padding: 10px;
                border-radius: 10px;
            }

            img {
                max-height: 56px;
            }
        }
    }
}