@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.career-add {
  padding: 0;
  .page-title {
    h2 {
      padding: 22px 0 0 0;
      color: $black;
      @include fontFamily("Roboto", 18px, normal, 400);
      text-align: center;
    }
  }
  .add-page {
    width: 75%;
    z-index: 8;
    margin: 0 auto;
    background: $white;
    border-radius: 10px;
    box-shadow: none;
    position: relative;
    top: -50px;

    .notice-text {
      padding: 40px 0;
      display: block;
      color: $primary-grey;
      @include fontFamily("Roboto", 14px, normal, 400);
      text-align: center;

      span {
        color: $primary-red;
      }
    }
  }
  .career-form {
    width: 70%;
    margin: 0 auto;

    .no-padding {
      padding: 0;
    }

    .career-form-heading {
      padding: 15px 0 59px 0;

      h3 {
        color: $black;
        @include fontFamily("Roboto", 24px, normal, 500);
        text-align: center;
        text-transform: capitalize;

        @media (max-width: 768px) {
          max-width: 500px;
          margin: 0 auto;
          word-break: break-word;
        }
      }

      .display-address {
        padding-top: 18px;
        justify-content: center;
      }
    }

    .referal-form {
      .referal-title {
        max-width: none;
        margin: 0 auto;
        padding: 0;

        h2 {
          color: $blue;
          display: inline-block;
          @include fontFamily("Roboto", 18px, normal, 700);
          text-transform: capitalize;
        }
      }

      .form-fields {
        padding: 0;
      }
    }

    .form-buttons {
      padding: 0 15px 30px;
      margin: 0 auto 120px;
      text-align: center;

      button {
        max-width: 600px;
        width: 100%;
        margin: 0;
        border-radius: 10px;
      }
    }

    .text-danger {
      color: #dc3545 !important;
    }
  }
  .overlay-component {
    .career-banner-overlay-component {
      text-align: center;

      h1 {
        padding-bottom: 40px;
        text-transform: initial;
        @include fontFamily("Roboto", 30px, normal, 400);
        &:after {
          content: "";
          width: 100px;
          height: 3px;
          position: absolute;
          top: 53px;
          left: 50%;
          transform: translateX(-50%);
          background: $white;
        }
        @media (max-width: 440px) {
          @include fontFamily("Roboto", 26px, normal, 400);
        }
      }

      h2 {
        display: inline-block;
        position: relative;
        color: $white;
        @include fontFamily("Roboto", 24px, normal, 400);

        @media (max-width: 440px) {
          @include fontFamily("Roboto", 20px, normal, 400);
        }
      }

      .description {
        margin-top: 20px;
        margin-bottom: 5px;

        span,
        p {
          @include fontFamily("Roboto", 16px, normal, 500);
        }

        span.banner-overlay-label {
          margin-right: 5px;
          display: inline-block;
        }
      }

      .display-address {
        span {
          font-size: 1rem;
          padding-left: 5px;
        }
        p {
          margin-left: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .add-page {
      width: 85%;
    }
    .career-form {
      width: 75%;
    }
  }
  @media screen and (max-width: 768px) {
    .add-page {
      width: auto;
      border-radius: 0;
    }
    .career-form {
      width: 90%;
    }
  }
  @media screen and (max-width: 500px) {
    .add-page .notice-text {
      padding: 40px 15px;
    }
    .career-form {
      width: auto;
      .input-field {
        padding-left: 15px;
      }
    }
  }
}
