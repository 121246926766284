@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.position-list {
  padding: 0 15px;
  .position-list-header {
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;

    .icons {
      display: flex;
    }

    span {
      display: inline-block;
      cursor: pointer;

      &.filter-icon {
        margin-right: 26px;

        img {
          width: 21px;
          height: 20px;
        }
      }
    }
  }
  .position-filter {
    li {
      .position-date-range {
        input {
          border: 0;
          border-bottom: 1px solid $grey;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
  .position-mobile-list {
    .position-list-header {
      padding: 12px 0 0 0;
    }
  }
}
.show-pay-rate-on-career-page {
  position: relative;
  .overlay {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: 1;
    cursor: not-allowed;
  }
}
.cancel-page {
  display: inline-block;
  cursor: pointer;
}
.add-position-mobile {
  position: fixed;
  bottom: 116px;
  right: 14px;
  z-index: 1;

  img {
    max-width: 40px;
  }
}
.help-text {
  color: #999;
  @include fontFamily("Roboto", 12px, "normal", 400);

  @media (max-width: 992px) {
    padding-left: 20px;
  }
}
.add-button {
  position: relative;

  .add-icon {
    width: 15px;
    position: absolute;
    top: 9px;
    left: 13px;
    z-index: 1;
  }
}
.position-list-title {
  h2 {
    text-transform: none !important;
  }
}
.remove-padding {
  padding-right: 0px !important;
}
.position-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .share-job-btn {
    margin: 18px;
  }
}

.position-list-buttons {
  display: inline-block;
  border: solid #999;
  border-radius: 3pt;
  cursor: pointer;
  margin-right: 10%;
  max-width: 45%;
  img {
    padding: 10%;
  }
}

.position-list-buttons:hover {
  border: solid #139ebf;
  border-radius: 3pt;
  cursor: pointer;
  margin-right: 10%;
  max-width: 45%;
  img {
    padding: 10%;
  }
}

.position-list-buttons:active {
  border: solid lightgreen;
  border-radius: 3pt;
  cursor: pointer;
  margin-right: 10%;
  max-width: 45%;
  img {
    padding: 10%;
  }
}

.postion-list-socialListBtns-container {
   display: flex;
   align-items: center;
}

.postion-list-socialListBtns-linkDiv {
  margin-right: 10%;
}

.postion-list-socialListBtns-textLink {
  background-color: #124a7c;
  color: white;
  cursor: pointer;
}


@media (max-width: 900px) {
  .list-view {
    .card {
      .card-footer {
        flex-direction: column;
        align-items: flex-start;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 5px 15px 15px !important;
        .card-footer-group {
          .card-footer-value {
            .status {
              text-align: left;
              margin-left: 0;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}
