@charset "UTF-8";
:root {
  --background-color: #fff;
  --font-primary: #333;
  --font-secondary: #666;
  --filter-font-title: #000;
  --dark-grey: #999;
  --primary-color: #124a7c; }

/*------------------------------------------------------------------
[Table of contents]

1. Set a rem font size with pixel fallback
2. Breakpoints
3. Animation and Keyframes
4. Cross browser opacity
5. Font Face
6. Autoprefix
7. Border

-------------------------------------------------------------------*/
/*================================================================
  1. Set a rem font size with pixel fallback

  Usage
  -----
  p {
    @include font-size(14px)
  }

  Output
  ------
  p {
    font-size: 14px; //Will be overridden if browser supports rem
    font-size: 0.8rem;
  }
==================================================================*/
/*================================================================
  2. Breakpoints

  Usage
  ---------
  .sidebar {
    width: 60%;
    float: left;
    margin: 0 2% 0 0;
    @include bp-small {
      width: 100%;
      float: none;
      margin: 0;
    }
  }

  Output:
  ----------
  .sidebar {
    width: 60%;
    float: left;
    margin: 0 2% 0 0;
    @media only screen and (max-width: 30){
      .sidebar{width: 100%; float: none; margin: 0;}
    }
  }
==================================================================*/
/**
 * Generic media query mixing for any random bp
 * $condition: eg. 'only screen and (max-width: 1024px)'
 */
/*================================================================
  3. Animation and Keyframes

  Usage:
  ---------
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }

  .element {
    width: 100px;
    height: 100px;
    background: black;
    @include animation('slide-down 5s 3');
  }
==================================================================*/
/*================================================================
  4. Cross browser opacity

  Usage:
  ----------
  .faded-text {
    @include opacity(0.8);
  }
==================================================================*/
/*================================================================
  5. Font Face

  Usage:
  ----------
  @include fontFace('MyFont','font/myfont');
==================================================================*/
/*================================================================
  6. Autoprefix

  Usage:
  ------
  .selector {
    @include prefix(transform, rotate(42deg));
  }
==================================================================*/
/*================================================================
  6. Border

  Usage:
  ------
  .selector {
    @include border('top/right/bottom/left', 1px, solid, color);
  }
==================================================================*/
.error-text-msg {
  font-size: 13px;
  padding-top: 7px;
  color: red; }

.file-error-msg {
  margin-bottom: 20px; }
  @media screen and (max-width: 500px) {
    .file-error-msg {
      padding-left: 15px; } }

.hide-error {
  display: none; }

.career-job-picker {
  padding: 20px 30px 5px 30px;
  background-color: #dbeffb; }
  .career-job-picker .hot-jobs {
    width: 90px;
    float: left;
    margin-top: 10px;
    padding: 35px 10px 30px 15px;
    border-radius: 5px;
    background: var(--background-color);
    color: #1c5281;
    font-weight: 900;
    line-height: 22px;
    font-size: 20px;
    border: 2px solid #1c5281;
    position: relative;
    text-transform: uppercase; }
    .career-job-picker .hot-jobs .text {
      padding-left: 10px; }
    .career-job-picker .hot-jobs .arrow-left {
      width: 0;
      height: 0;
      top: 5px;
      right: 0px;
      position: absolute;
      border-right: 10px solid #1c5281;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent; }
  .career-job-picker .career-hot-jobs-list .job-list-slider {
    display: flex;
    cursor: pointer;
    margin: 10px 0px 10px 25px;
    padding: 10px;
    min-height: 110px;
    position: relative;
    text-align: center;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
    background-color: var(--background-color);
    text-transform: capitalize;
    border-bottom: 4px solid #1c5281; }
    .career-job-picker .career-hot-jobs-list .job-list-slider .job-title {
      color: var(--font-primary);
      font-weight: 500; }
    .career-job-picker .career-hot-jobs-list .job-list-slider .job-title-overflow {
      color: var(--font-primary);
      font-size: 16px;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .career-job-picker .career-hot-jobs-list .job-list-slider .job-location {
      padding-top: 15px;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 22px;
      color: #666666;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-transform: none;
      text-overflow: ellipsis;
      color: var(--font-secondary); }

.search-autocomplete-form {
  padding: 0 !important; }

.career-search-filter-input {
  width: 50%;
  display: flex;
  padding: 0 10px;
  background: #fff;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 1px solid #cccccc; }
  .career-search-filter-input .job-search-icon {
    margin-top: -1px;
    margin-right: 10px; }
    .career-search-filter-input .job-search-icon .icon-search {
      color: var(--primary-color); }
  .career-search-filter-input .form-group {
    width: 100%;
    margin: 0 !important; }
    .career-search-filter-input .form-group .d-block .form-control {
      border-bottom: none !important; }
    .career-search-filter-input .form-group .d-block input {
      display: block;
      width: 100% !important;
      height: 30px;
      text-overflow: ellipsis; }
      .career-search-filter-input .form-group .d-block input:focus {
        box-shadow: none;
        border-color: #fff !important;
        border-bottom: none !important; }

.google-search-autocomplete {
  width: 50%;
  padding: 5px 10px;
  background-color: var(--background-color);
  border-radius: 10px; }
  .google-search-autocomplete .address .position-relative {
    margin-bottom: 0px;
    display: flex; }
    .google-search-autocomplete .address .position-relative .d-block {
      margin-left: 25px; }
      .google-search-autocomplete .address .position-relative .d-block .location-search-input {
        height: 35px;
        border-bottom: none !important; }
      .google-search-autocomplete .address .position-relative .d-block .autocomplete-dropdown-container {
        left: -10px;
        right: -10px;
        width: auto; }
      @media screen and (max-width: 1024px) {
        .google-search-autocomplete .address .position-relative .d-block {
          margin-left: 10%; } }
    .google-search-autocomplete .address .position-relative .close-icon {
      top: 7px; }
  .google-search-autocomplete .d-block .form-control {
    padding-left: 35px;
    border: none !important;
    border-bottom: 1px solid gray !important; }
  .google-search-autocomplete .d-block .form-control:focus {
    box-shadow: none !important; }

.mobile-google-search-autocomplete {
  background-color: #fff; }
  .mobile-google-search-autocomplete .address .position-relative {
    margin-bottom: 0px; }
    .mobile-google-search-autocomplete .address .position-relative .d-block {
      margin-left: 27px; }
      .mobile-google-search-autocomplete .address .position-relative .d-block .location-search-input {
        height: 35px;
        border-bottom: none !important; }
      .mobile-google-search-autocomplete .address .position-relative .d-block .autocomplete-dropdown-container {
        width: auto;
        right: 0;
        left: 0; }
  .mobile-google-search-autocomplete .address .d-block .form-control:focus {
    box-shadow: none !important; }
  .mobile-google-search-autocomplete input::-ms-clear {
    display: none; }

.career-details .container .career-card .main-details .details .details-header .more-detail-header {
  text-transform: none; }

.career-body-container {
  height: auto; }
  .career-body-container .career-header-button button {
    padding: 14px 21px;
    border-radius: 6px;
    text-transform: uppercase; }
    .career-body-container .career-header-button button img {
      padding-left: 5px; }
    .career-body-container .career-header-button button:focus {
      outline: 0; }

.border-marging .app-footer {
  position: fixed !important; }

.career-list .list-header {
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between; }
  .career-list .list-header h2 {
    color: #a4b4bf; }
  .career-list .list-header span {
    display: inline-block;
    cursor: pointer; }
    .career-list .list-header span.filter-icon img {
      width: 21px;
      height: 20px; }

.career-list .career-filter {
  padding-top: 55px; }
  .career-list .career-filter li .position-date-range input {
    border: 0;
    border-bottom: 1px solid #dee4e9; }
    .career-list .career-filter li .position-date-range input:focus {
      box-shadow: none; }

.career-list .desktop-list .list-view .list,
.career-list .mobile-list .list-view .list {
  display: block; }

.career-list .desktop-list .banner,
.career-list .mobile-list .banner {
  background: #124a7c !important; }
  .career-list .desktop-list .banner:before,
  .career-list .mobile-list .banner:before {
    background: #124a7c !important; }

.career-list .desktop-list h3.career-banner-heading,
.career-list .mobile-list h3.career-banner-heading {
  position: relative;
  color: var(--background-color);
  font-family: "Roboto";
  font-size: 30px;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize; }

.career-list .desktop-list h3:after,
.career-list .mobile-list h3:after {
  content: "";
  width: 100px;
  height: 3px;
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--background-color); }

@media screen and (max-width: 900px) {
  .career-list .desktop-list .action-buttons button,
  .career-list .mobile-list .action-buttons button {
    width: 100%; } }

.career-list .desktop-list .pagination-wrapper {
  margin: 0 0 100px 0; }

.career-list .mobile-list h3.career-banner-heading {
  font-size: 24px; }

.career-list .mobile-list .list-header {
  padding: 12px 0 0 0; }

.applicants-experience div {
  margin-right: 10px;
  display: inline-flex;
  vertical-align: top; }

html {
  background: var(--background-color); }

.career-direct-form__form-layout {
  position: relative;
  top: -50px;
  z-index: 8; }
  .career-direct-form__form-layout .field button {
    margin-top: 20px;
    width: 95%;
    font-size: 13px;
    font-family: "Roboto";
    text-transform: uppercase;
    border-radius: 6px;
    font-weight: 700; }
  .career-direct-form__form-layout .field .button-submit {
    color: var(--background-color);
    margin-left: 10px;
    background-color: #124a7c;
    border: 1px solid #124a7c; }
  .career-direct-form__form-layout .field .button-cancel {
    border: 1px solid #124a7c;
    background-color: var(--background-color);
    color: #124a7c; }
  .career-direct-form__form-layout .applicants-experience div {
    margin-right: 0px;
    padding-right: 20px; }
  .career-direct-form__form-layout .margin-bottom {
    margin-bottom: 30px; }

.career-direct-form .form-heading {
  margin: 25px 0px;
  display: inline-block;
  font-size: 18px;
  color: #124a7c;
  font-family: "Roboto";
  font-weight: 700;
  text-transform: capitalize; }

.direct-apply-link {
  display: flex;
  margin-top: 30px;
  justify-content: space-between; }
  .direct-apply-link a {
    float: right; }
    .direct-apply-link a button {
      padding: 10px 25px;
      font-family: "Roboto";
      font-size: 14px;
      text-transform: uppercase; }
  .direct-apply-link .join-talentdome {
    color: #124a7c;
    padding: 8px 40px;
    border: 2px solid #124a7c;
    background: var(--background-color); }
  .direct-apply-link .header {
    font-size: 28px;
    font-weight: 500;
    position: relative;
    font-family: "Roboto";
    color: var(--primary-color);
    text-transform: capitalize; }
    .direct-apply-link .header .underline {
      width: 100px;
      height: 3px;
      position: absolute;
      bottom: -5px;
      left: 54px;
      transform: translateX(-50%);
      background: var(--primary-color); }

.mobile-list .pagination-wrapper {
  margin-bottom: 60px; }
  @media screen and (max-width: 600px) {
    .mobile-list .pagination-wrapper {
      margin-bottom: 20px; } }

.career-list .desktop-list {
  box-shadow: none; }
  .career-list .desktop-list .btn-refer-talentdome {
    margin-left: 15px;
    padding: 10px 30px;
    background-color: #1c5281;
    color: var(--background-color); }

.career-list .career-position-list {
  overflow: hidden;
  background: #fff; }
  .career-list .career-position-list .career-list-details {
    width: 95%;
    margin: 0 auto; }
    .career-list .career-position-list .career-list-details .action-buttons .share-job-btn {
      margin: 0;
      font-size: 13px !important; }
    .career-list .career-position-list .career-list-details .action-buttons .join-talentdome {
      margin-left: 15px; }
  .career-list .career-position-list .card-header {
    display: block !important; }
  .career-list .career-position-list .career-list-details .list-view .list {
    display: block; }
  .career-list .career-position-list .card-footer {
    display: block !important;
    margin: 0 !important;
    background-color: #dbeffb;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px; }
  .career-list .career-position-list .card-footer-group {
    display: block !important; }
  .career-list .career-position-list .page-list-details {
    margin-bottom: 100px;
    background: transparent; }
    .career-list .career-position-list .page-list-details .pagination-wrapper {
      margin-bottom: 0; }

.career-list form {
  padding-top: 10px;
  padding-left: 15px; }

.career-desktop-filters-list {
  background-color: var(--background-color);
  width: 265px;
  height: 100vh;
  float: left;
  box-shadow: 0px 0px 13.81px -5.19px rgba(0, 0, 0, 0.4); }
  .career-desktop-filters-list .desktop-filter-container {
    border-radius: 0px;
    border: none;
    box-shadow: none;
    background-color: var(--background-color); }
    .career-desktop-filters-list .desktop-filter-container h3 {
      color: var(--filter-font-title); }
    .career-desktop-filters-list .desktop-filter-container .desktop-filters-options
.desktop-filter-accordian
.desktop-filter-accordian-header
h3 {
      margin-left: 17px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

.isSticky {
  position: fixed;
  top: 0;
  left: 0; }

.app {
  background: var(--background-color); }

.career-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .career-card-header .career-card-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .career-card-header .title h2 {
    max-width: 100%;
    overflow: unset;
    text-overflow: unset;
    white-space: normal;
    color: var(--filter-font-title);
    font-family: "Roboto";
    font-size: 20px;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400; }
  .career-card-header .career-card-header-date {
    color: var(--filter-font-title);
    font-family: "Roboto";
    font-size: 12px;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400; }

.career-card-body {
  padding: 20px 0 15px 0;
  font-size: 14px;
  font-weight: 500;
  border-top: 2px solid #dddddd;
  white-space: normal !important; }
  .career-card-body .career-card-body-icons {
    display: flex;
    padding-bottom: 20px;
    font-size: 14px;
    font-weight: 500; }
    .career-card-body .career-card-body-icons .display-address p {
      font-size: 14px !important;
      font-weight: 500; }
    .career-card-body .career-card-body-icons .icons {
      padding-right: 5px; }
    .career-card-body .career-card-body-icons .footer-icons {
      width: 30%;
      display: flex;
      align-items: center;
      color: var(--font-primary); }
      .career-card-body .career-card-body-icons .footer-icons:last-child {
        width: auto; }
      .career-card-body .career-card-body-icons .footer-icons .footer-text {
        padding-top: 5px; }
      .career-card-body .career-card-body-icons .footer-icons .icon-briefcase {
        font-size: 18px; }
      .career-card-body .career-card-body-icons .footer-icons .location-footer-text {
        padding-top: 2px; }
      .career-card-body .career-card-body-icons .footer-icons .category-footer-text {
        padding-top: 1px; }
      .career-card-body .career-card-body-icons .footer-icons .experience-footer-text {
        padding-top: 3px; }
  .career-card-body .career-card-body-container {
    line-height: 24px;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--font-primary); }
    .career-card-body .career-card-body-container__detail {
      padding-left: 5px; }
    .career-card-body .career-card-body-container span {
      font-weight: 300;
      vertical-align: initial;
      color: var(--font-secondary); }

.hide-div {
  display: none; }

.career-card-footer {
  display: flex;
  padding: 15px 0;
  cursor: default;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  background-color: #dbeffb;
  justify-content: space-between; }
  .career-card-footer .cancel-button {
    margin: 0 !important;
    background-color: var(--background-color);
    margin-right: 10px !important;
    padding: 10px !important;
    background: var(--background-color); }
  .career-card-footer .submit-button {
    margin: 0 !important;
    padding: 10px !important; }
  .career-card-footer .referal-bonus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #124a7c;
    text-transform: uppercase; }
    .career-card-footer .referal-bonus .money-bag .icon-bag {
      font-size: 24px;
      padding-right: 0; }
    .career-card-footer .referal-bonus span {
      font-weight: 500;
      margin-bottom: 0px;
      font-size: 14px;
      vertical-align: bottom; }
      .career-card-footer .referal-bonus span strong span {
        font-size: 18px; }
    .career-card-footer .referal-bonus .referral-bonus-text {
      vertical-align: bottom; }
  .career-card-footer .referral-buttons {
    display: flex;
    align-items: center;
    justify-content: right; }
  .career-card-footer .footer-icons {
    display: flex;
    align-items: end; }
    .career-card-footer .footer-icons .icon-calendar {
      font-size: 16px; }
  .career-card-footer .icons {
    padding-right: 10px; }

textarea#cover-letter {
  max-height: 160px;
  overflow: auto;
  border: none;
  border-bottom: 1px solid #bdd6ee;
  resize: none; }

.career-direct-form-page {
  background: var(--background-color);
  margin-bottom: 35px;
  padding: 0px 20px 20px 15px;
  border-radius: 10px; }
  .career-direct-form-page__heading {
    padding-top: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 21px;
    color: #999999;
    font-family: "Roboto";
    font-weight: 400;
    text-align: center; }
  .career-direct-form-page__form {
    width: 65%;
    margin: 0 auto; }
  .career-direct-form-page .star {
    color: red; }

.desktop-search-filter-section {
  width: 100%;
  display: flex;
  align-items: center; }
  .desktop-search-filter-section .find-jobs-button {
    width: 13%; }
  .desktop-search-filter-section .desktop-search-with-find-jobs {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .desktop-search-filter-section .desktop-search-filter-input-parent {
    width: 67%;
    display: flex;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 0px 13.81px -5.19px rgba(0, 0, 0, 0.4); }
    .desktop-search-filter-section .desktop-search-filter-input-parent .search-filter-input {
      width: 50%;
      padding: 10px 0 10px 0; }
  .desktop-search-filter-section .desktop-career-page-filter-redesign {
    width: 20%;
    height: auto;
    position: relative;
    margin-right: 20px;
    border-radius: 10px; }
    .desktop-search-filter-section .desktop-career-page-filter-redesign .career-desktop-filters-list {
      height: auto;
      width: 100%;
      border-radius: 10px; }
      .desktop-search-filter-section .desktop-career-page-filter-redesign .career-desktop-filters-list form {
        padding: 0px; }
      .desktop-search-filter-section .desktop-career-page-filter-redesign .career-desktop-filters-list .desktop-filter-container {
        width: 100%;
        border-radius: 10px;
        background: var(--background-color); }
    .desktop-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-container .desktop-filters-options {
      padding-bottom: 0px; }
    .desktop-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-accordian-body-container .desktop-filter-accordian-body {
      position: absolute;
      padding: 0px;
      top: 43px;
      z-index: 5;
      width: 100%;
      padding-top: 15px;
      padding-left: 20px;
      background: white; }
    @media (max-width: 1025px) and (min-width: 1024px) {
      .desktop-search-filter-section .desktop-career-page-filter-redesign {
        width: 30%;
        height: auto; }
        .desktop-search-filter-section .desktop-career-page-filter-redesign .career-desktop-filters-list {
          height: auto;
          border-radius: 10px; }
          .desktop-search-filter-section .desktop-career-page-filter-redesign .career-desktop-filters-list form {
            padding: 0px; } }
    .desktop-search-filter-section .desktop-career-page-filter-redesign ​ .career-list .desktop-list h3:after,
    .desktop-search-filter-section .desktop-career-page-filter-redesign .career-list .mobile-list h3:after {
      bottom: 0px;
      display: none; }
    .desktop-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-accordian .desktop-filter-accordian-header {
      padding: 13px 15px; }
      .desktop-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-accordian .desktop-filter-accordian-header h3 {
        font-size: 16px;
        color: #333333;
        font-weight: 500; }
      .desktop-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-accordian .desktop-filter-accordian-header h3:after {
        bottom: 0px;
        display: none; }
    .desktop-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-container .career-desktop-filter-header {
      display: none; }

.desktop-search-filter-section .find-jobs-button button,
.mobile-search-filter-section .find-jobs-button button {
  width: 100%;
  padding: 14px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 40px;
  background: #124a7c; }

.desktop-county-filter {
  z-index: 0; }
  .desktop-county-filter .county-icon {
    position: absolute;
    top: 12px;
    z-index: 1;
    left: 10px; }
    .desktop-county-filter .county-icon .icon-location1 {
      color: var(--primary-color); }
  .desktop-county-filter .desktop-filter-accordian-body .checkboxes .form-check .d-block input[type="checkbox"] {
    width: 95%; }

.mobile-filters {
  display: flex;
  font-weight: 500;
  padding: 20px 30px;
  letter-spacing: 1px;
  background: #124a7c;
  text-transform: uppercase;
  justify-content: space-between;
  color: var(--background-color); }

.desktop-search-filter-section {
  background: #dbeffb;
  padding: 15px 30px 30px 30px; }
  .desktop-search-filter-section .search-autocomplete {
    margin: 0 15px;
    padding: 0; }

.desktop-search-filter-section .search-autocomplete .css-1oubc3t,
.desktop-search-filter-section .search-autocomplete .css-1mr44tx,
.desktop-search-filter-section .search-autocomplete .css-1mr44tx:focus,
.desktop-search-filter-section .search-autocomplete .css-1mr44tx:hover {
  border-bottom: none; }

.desktop-search-filter-section .search-autocomplete .css-1492t68 {
  font-size: 16px;
  color: #333333;
  font-weight: 500; }

.mobile-search-filter-section .search-autocomplete .css-1oubc3t,
.mobile-search-filter-section .search-autocomplete .css-1mr44tx,
.mobile-search-filter-section .search-autocomplete .css-1mr44tx:focus,
.mobile-search-filter-section .search-autocomplete .css-1mr44tx:hover {
  margin: 0 10px;
  border-bottom: 1px solid gray; }

.mobile-direct-apply {
  padding-top: 30px;
  padding-bottom: 10px;
  position: relative; }
  .mobile-direct-apply .header {
    font-size: 25px;
    font-weight: 500;
    position: relative;
    padding-left: 20px;
    font-family: "Roboto";
    color: var(--primary-color);
    text-transform: capitalize; }
    .mobile-direct-apply .header .underline {
      width: 100px;
      height: 3px;
      position: absolute;
      bottom: -5px;
      left: 73px;
      transform: translateX(-50%);
      background: var(--primary-color); }
  .mobile-direct-apply .submit {
    text-transform: uppercase; }
  .mobile-direct-apply .join-talentdome {
    margin: 0 10px;
    color: #124a7c;
    background: #fff;
    border: 2px solid #124a7c;
    background: var(--background-color); }
  .mobile-direct-apply .refer-to-talentdome {
    padding: 11px 15px; }
  .mobile-direct-apply .join-talentdome,
  .mobile-direct-apply .refer-to-talentdome {
    border-radius: 20px;
    font-weight: 600; }
  @media screen and (max-width: 700px) {
    .mobile-direct-apply {
      flex-direction: column;
      text-align: left; }
      .mobile-direct-apply .header {
        font-size: 22px; }
      .mobile-direct-apply .action-buttons {
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px; }
      .mobile-direct-apply .join-talentdome {
        margin: 0; }
      .mobile-direct-apply .join-talentdome,
      .mobile-direct-apply .refer-to-talentdome {
        padding: 10px;
        font-size: 12px;
        margin-right: 5px; } }
  @media screen and (max-width: 700px) {
    .mobile-direct-apply .share-job-btn {
      margin-bottom: 10px; } }
  @media screen and (max-width: 320px) {
    .mobile-direct-apply .join-talentdome,
    .mobile-direct-apply .refer-to-talentdome {
      font-size: 10px; } }

@media screen and (max-width: 1048px) {
  .direct-apply-link .header {
    font-size: 24px; }
  .direct-apply-link .join-talentdome {
    padding: 8px 25px; }
  .direct-apply-link a button {
    padding: 10px 20px; }
  .career-list .desktop-list .btn-refer-talentdome {
    padding: 10px 20px; } }

@media screen and (max-width: 992px) {
  .mobile-search-filter-section {
    padding: 20px 30px;
    background: #fff; }
    .mobile-search-filter-section .mobile-search-input {
      width: 100%;
      padding: 10px;
      border-radius: 0;
      border-right: none;
      border-bottom: 1px solid #1b232e; }
    .mobile-search-filter-section .find-jobs-button {
      margin-top: 30px;
      text-align: center;
      border-radius: 40px;
      background-color: #124a7c; }
    .mobile-search-filter-section .desktop-career-page-filter-redesign .career-desktop-filters-list {
      height: auto;
      float: inherit;
      width: 100%;
      box-shadow: none; }
    .mobile-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-container {
      width: 100%; }
      .mobile-search-filter-section .desktop-career-page-filter-redesign .desktop-filter-container .desktop-filter-header {
        display: none; }
    .mobile-search-filter-section .desktop-career-page-filter-redesign .desktop-filters-options {
      padding: 0; }
      .mobile-search-filter-section .desktop-career-page-filter-redesign .desktop-filters-options .desktop-filter-accordian .desktop-filter-accordian-header {
        padding: 16px; }
        .mobile-search-filter-section .desktop-career-page-filter-redesign .desktop-filters-options .desktop-filter-accordian .desktop-filter-accordian-header h3 {
          font-weight: 500; }
    .mobile-search-filter-section .mobile-county-filters {
      display: flex;
      position: relative;
      border-bottom: 1px solid #1b232e; }
    .mobile-search-filter-section .county-icon {
      position: absolute;
      top: 14px;
      left: 10px; }
  .mobile-google-search-autocomplete {
    border-bottom: 1px solid #1b232e; }
    .mobile-google-search-autocomplete .d-block .form-control {
      border: none !important;
      padding-left: 40px !important;
      border-bottom: none !important; }
    .mobile-google-search-autocomplete .address {
      padding: 8px; }
      .mobile-google-search-autocomplete .address .form-group {
        margin: 0; }
  .career-direct-form-page {
    border-radius: 0px; }
    .career-direct-form-page__form {
      width: 100%; }
  .card-header {
    padding: 15px 15px 0 !important; }
  .card-body {
    padding: 0 15px 5px 15px !important; }
    .card-body .career-card-body .career-card-body-icons {
      padding-bottom: 0;
      flex-direction: column; }
      .card-body .career-card-body .career-card-body-icons .footer-icons {
        width: 100%;
        padding-bottom: 15px; }
    .card-body .career-card-body .career-card-body-container {
      max-height: initial; }
  .card-footer {
    margin: 0 !important; }
  .career-card-footer {
    display: flex;
    padding: 10px 0 0;
    flex-direction: column;
    align-items: flex-start; }
    .career-card-footer .footer-icons {
      padding-bottom: 15px; }
    .career-card-footer .referral-buttons {
      align-items: flex-start;
      flex-direction: column-reverse; }
    .career-card-footer .referal-bonus {
      margin-top: 10px; }
  .card-footer,
  .card-footer-group {
    display: block !important; }
  .logged-in-action-buttons {
    margin-right: 20px; }
    .logged-in-action-buttons .share-job-btn {
      width: auto; } }

@media screen and (max-width: 600px) {
  .career-job-picker {
    padding: 15px; }
    .career-job-picker .career-hot-jobs-list .job-list-slider {
      margin: 10px 10px 10px 20px; }
  .career-list form {
    padding: 0px !important; }
  .search-autocomplete-form .mobile-search-filter-section {
    padding-bottom: 15px;
    padding-top: 0px; }
  .search-autocomplete-form .search-filter-input {
    padding-top: 5px; }
  .career-direct-form__form-layout .field .button-submit {
    margin-left: 0px !important; }
  .mobile-direct-apply .header {
    padding-left: 15px; } }

.close-option {
  cursor: pointer;
  font-size: 22px;
  color: var(--primary-color); }

.career-page-input::placeholder {
  color: #333333;
  font-weight: 500;
  opacity: 1;
  /* Firefox */ }

.career-page-input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333333 !important;
  font-weight: 500; }

.career-page-input ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #333333;
  font-weight: 500; }

.career-page-input ::-ms-clear {
  display: none; }

.career-page-input::placeholder, .career-page-input::-ms-input-placeholder, .career-page-input::-webkit-input-placeholder {
  color: #333333;
  font-family: "Roboto";
  font-size: 16px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500; }
