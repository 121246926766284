@import "_variables";
@import "_mixins";
@import "font.css";
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $white;
  -webkit-text-fill-color: $black;
  transition: background-color 5000s ease-in-out 0s;
}

.body-container {
  @include prefix(height, calc(100% - 112px));
  font-family: "Roboto";

  &.career-body-container {
    height: 100%;

    main {
      height: 100%;
    }
  }
}

.wrapper {
  width: 95%;
  max-width: 1380px;
  margin: 0 auto;
}

.app {
  height: 100%;
}

.body-scroll-hide {
  overflow: hidden;
}

.app-breadcrumb {
  padding: 20px 30px 0;
  nav {
    ol {
      padding: 0;
      background: inherit;

      li.breadcrumb-item {
        display: flex;
        align-items: center;
        color: #999;
        @include fontFamily("Roboto", 12px, normal, 400);

        & + .breadcrumb-item {
          &::before {
            content: "/";
          }
        }

        a {
          color: #333;
          @include fontFamily("Roboto", 12px, normal, 400);
          text-decoration: none;
        }
      }
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-results {
  padding: 20px 0;
  @include fontFamily("Roboto", 20px, normal, 400);
  text-align: center;
}

@include keyframes("slide-down") {
  0% {
    height: 0;
  }
  100% {
    height: auto;
  }
}

@include keyframes("slide-up") {
  0% {
    height: auto;
  }
  100% {
    height: 0;
  }
}

@include keyframes("slide-bottom-to-top") {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}

@include keyframes("slide-top-to-bottom") {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

.form-control {
  border: none;
  border-bottom: 1px solid #bdd6ee;
  &:disabled {
    cursor: not-allowed;
    background: transparent;
  }
}

.button {
  padding: 12px 20px;
  margin: 15px;
  @include fontFamily("Roboto", 13px, "normal", 500);

  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
}

.cancel-page {
  margin: 0 15px 0 25px;
  padding: 12px 20px;
  border-radius: 8px;
  @include fontFamily("Roboto", 13px, normal, 500);
  color: $blue;
  background: transparent;
  border: 1px solid $blue;
  text-transform: uppercase;
}

.cancel-button {
  @extend .button;
  color: $blue;
  background: transparent;
  border: 1px solid $blue;
  text-transform: uppercase;
}

.submit-button {
  @extend .button;
  color: $white;
  background: $blue;
  border: 1px solid $blue;
  text-transform: uppercase;
}
.send-button {
  @extend .button;
  color: $white;
  margin: 10px 0px 0px 0px;
  border-radius: 8px;
  padding: 12px 30px;
  background: $blue;
  border: 1px solid $blue;
  text-transform: uppercase;
}

.edit-button {
  @extend .button;
  color: $blue;
  background: transparent;
  border: 1px solid $blue;
  text-transform: uppercase;
}

.refer-button {
  @extend .button;
  color: $white;
  background: transparent;
  border: 1px solid $white;
  text-transform: uppercase;
  border-radius: 5px;
}

.apply-button {
  @extend .button;
  color: $blue;
  background: $white;
  border: 1px solid $white;
  text-transform: uppercase;
  border-radius: 5px;
}

.white-button {
  @extend .button;
  color: $secondary-blue;
  background: transparent;
  border: 1px solid $secondary-blue;
}
.share-job-btn {
  margin-right: 15px;
  padding: 10px 20px 8px 13px;
  font-size: 15px !important;
  &::before {
    content: "";
    width: 18px;
    height: 18px;
    display: inherit;
    vertical-align: middle;
    margin-right: 6px;
    background: url("../images/icon-share.png") no-repeat;
    background-size: contain;
  }
  @media screen and (max-width: 992px) {
    margin-right: 10px;
    font-size: 13px !important;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 14px 10px 8px;
    margin-right: 5px;
    font-size: 12px !important;
    &::before {
      width: 16px;
      height: 15px;
      margin-right: 5px;
    }
  }
}

input:disabled::-webkit-input-placeholder {
  color: $dark-grey;
}

input:disabled:-moz-placeholder {
  color: $dark-grey;
}

input:disabled::-moz-placeholder {
  color: $dark-grey;
}

input:disabled:-ms-input-placeholder {
  color: $dark-grey;
}

input,
textarea {
  color: #000 !important;
}

.invalid-input {
  border-bottom: 1px solid $primary-red !important;
}

.text-danger {
  padding-top: 5px;
  color: $primary-red !important;
  @include fontFamily("Roboto", 11px, normal, 400);
  display: inline-block;
}

.toast-message {
  @include fontFamily("Roboto", 14px, normal, 400);
}

.required-field {
  color: $primary-red;
}

a {
  text-decoration: none !important;
}

.position-details-notify-distribution-list {
  margin: 0 30px;
}

.position-details-notify-distribution-list-title {
  color: #124a7c;
  font-weight: 500;
  padding: 10px 0px 15px 0px;
}

.notification-message-text {
  color: #124a7c;
  font-weight: 400;
  font-size: 15px;
  margin-top: 12px;
}

.selected-distribution-groups {
  font-weight: 600;
  text-transform: capitalize;
}

.send-notification-link {
  font-weight: 500;
  border-bottom: 1px solid #124a7c;
}

.distribution-edit-contact-list-title,
.contact-edit-distribution-list-title {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 500;
  padding: 0px 0px 15px 0px;
  text-transform: uppercase;
}

.list {
  padding: 22px;

  .desktop-list {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .list-header {
    padding: 33px 42px 37px;
    background-color: #fff;

    h2 {
      color: $blue;
      @include fontFamily("Roboto", 30px, normal, 400);
      text-transform: uppercase;

      span {
        @include fontFamily("Roboto", 14px, normal, 400);
        text-transform: lowercase;
      }
    }

    .icons {
      display: flex;
    }

    span {
      display: inline-block;
      cursor: pointer;

      &.filter-icon {
        margin-right: 26px;

        img {
          width: 21px;
          height: 20px;
        }
      }
    }
  }

  .desktop-filters {
    background: $white;

    .filter-sort-by {
      padding-right: 42px;
      display: flex;
      align-items: flex-start;
    }

    .sort-by-filter {
      width: 283px;
      min-width: 283px;
      margin-top: 40px;
    }

    .experience-range-filter {
      max-width: 300px;
      padding: 0 42px 40px;
    }
  }

  .filter {
    li {
      .date-range {
        input {
          border: 0;
          border-bottom: 1px solid $grey;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  &.mobile-page-list {
    padding: 0;
    display: none;
    background: #eee;

    @media (max-width: 992px) {
      padding-bottom: 42px;
      display: block;
    }

    .mobile-list {
      nav {
        display: flex;
        justify-content: center;
      }
      .list-header {
        padding: 20px;
        background: $white;
        box-shadow: 0px 0px 7.68px 0.32px rgba(0, 0, 0, 0.25);

        h2 {
          font-size: 24px;
        }

        a {
          button {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            right: 20px;
            bottom: 70px;
            z-index: 10;
            font-size: 0;

            &::before {
              left: initial;
              top: initial;
            }
          }
        }
      }

      .mobile-filter-section {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent;

        p {
          @include fontFamily("Roboto", 16px, normal, 400);

          &.list-count {
            color: #999;
          }

          &.mobile-filter-text {
            color: $blue;
            cursor: pointer;
          }
        }
      }

      .list-view {
        .list {
          padding: 0;
          margin: 0px 15px 15px 15px;
          &:first-child {
            margin-top: 0;
          }
          .card {
            border-radius: 10px;
            box-shadow: 0px 0px 7.68px 0.32px rgba(0, 0, 0, 0.25);

            .card-header-title {
              h2 {
                max-width: 300px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }

        &:last-child {
          padding-bottom: 50px;
        }
      }
    }
  }

  &.career-list {
    padding: 0;

    .mobile-list {
      .mobile-filter-section {
        padding-bottom: 0 !important;
      }
    }

    .desktop-list-header-filter {
      margin-top: 35px;
      box-shadow: 0px 0px 7.68px 0.32px rgba(0, 0, 0, 0.25);
    }

    .list {
      padding: 0;

      &:first-child {
        padding-top: 15px;
      }
    }

    .mobile-filter {
      bottom: 60px;
    }

    .list-view {
      .list {
        a {
          width: 100%;
        }
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 100px;
    }
  }
}
.width-50 {
  width: 50%;
}

.width-40 {
  width: 40%;
}

.width-35 {
  width: 40%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
  overflow: auto;
}

.width-15 {
  width: 15%;
}

.width-13 {
  width: 13%;
}

.width-12 {
  width: 12%;
}

.width-10 {
  width: 10%;
}

.width-5 {
  width: 5%;
}

.width-4 {
  width: 4%;
}

.hide-content {
  background: #d7d7d7;
  width: 220px;
  height: 22px;
  display: inline-block;
  border-radius: 5px;
}

.title-hide {
  width: initial;
  height: initial;
  padding: 6px 30px 6px 0;
  display: block;
  text-align: left;
  color: $black;
}

.characters-limit {
  position: absolute;
  right: 0px;
  margin-top: 5px;
  display: block;
  text-align: right;
  color: $primary-grey;
  @include fontFamily("Roboto", 12px, "normal", 400);
}

.talentpool-desktop-filters {
  @media (max-width: 768px) {
    display: none;
  }
}

.content-add-page {
  box-shadow: 0px 0px 3.92px 0.08px rgba(0, 0, 0, 0.25);

  .content-add {
    background: $white;

    .page-title {
      padding: 20px 17px 0;

      h2 {
        color: $blue;
        @include fontFamily("Roboto", 30px, normal, 400);
        text-transform: capitalize;
      }

      @media (max-width: 768px) {
        border: 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (max-width: 440px) {
    padding-bottom: 5px;
  }
}

.no-border {
  border: 0 !important;
}

@media (max-width: 768px) {
  .page-title {
    padding: 13px 0;
    border-bottom: 1px solid #ccc;
  }
}

// New Design styling

.search-by-applicant-filter {
  width: 47%;
  overflow: hidden;
  position: relative;
  margin-bottom: 19px;

  #search-box {
    width: 100%;
    min-height: 40px;
    padding-left: 40px;
    &::placeholder {
      color: #808080;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .candidate-icon {
    position: absolute;
    left: 7px;
    top: 12px;
  }
  .clear-option {
    color: #083761;
    position: absolute;
    right: 5px;
    top: 12px;
    cursor: pointer;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    border: none;
    background: #fff;
    padding-bottom: 20px;

    #search-box {
      padding-left: 20px;
    }

    .candidate-icon {
      position: absolute;
      right: 15px;
      left: auto;
      top: 11px;
    }
    .clear-option {
      right: 40px;
      top: 12px;
    }
  }
}

.search-filter-section {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  box-shadow: 0px 0px 3.92px 0.08px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    padding: 15px 0;
  }
}

.application-desktop-page,
.talentpool-desktop-page {
  .search-filter-input {
    width: 47%;
    .search-autocomplete {
      .search-icon {
        left: 5px;
        top: 10px;
      }
    }
  }
}

.list {
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    display: block;
  }
}

.desktop-list {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 0px 3.92px 0.08px rgba(0, 0, 0, 0.15);
}

.page-list-details {
  padding: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  border-radius: 15px;

  .page-numbers-text {
    color: #999;
    @include fontFamily("Roboto", 14px, normal, 400);
  }

  .page-export {
    width: 17%;
    // text-align: end;
  }

  .application-export-btn {
    background-color: $dark-grey;
    color: $white;
    text-transform: capitalize;
    font-size: 12px;
    padding: 7px 10px;
    border-radius: 5px;
  }
}

.talentpool-tab {
  .search-filter-section {
    margin-bottom: 15px;
  }
}

.bs-tooltip-top {
  position: absolute !important;
  top: 12px !important;
  z-index: 0;
}

.bs-tooltip-bottom {
  position: absolute !important;
  top: -14px !important;
  z-index: 0;
}

.mobile-list {
  .list {
    a {
      width: 100%;
    }
  }
}
.talentPool-width {
  width: 80%;
  .tabular-view ul.list-items li.item ul.item-row li {
    text-transform: none;
  }
}

.application-desktop-filter {
  span {
    text-transform: none !important;
  }
}
