@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.google-search{
    @media (max-width: 600px) {
        margin-top: 20px;
        padding-bottom: 20px;
    }
    &__option {
        background: #eeeeee;
        display: flex;
        align-items: center;
    &__radio-button {
        margin-right: 10px;
        padding-top: 15px;
        text-align: center;

        input[type="radio"]{
            width: 15px;
            height: 20px;
            margin: 0;
            padding: 0;
            opacity: 0;
            cursor: pointer;
        }
        input[type="radio"] + span{
            width: inherit;
            height: 20px;
            display: inline-block;
            padding-left: 28px;
            background: url(../../images/radio.png) no-repeat 0 0;
            text-transform: capitalize;
            cursor: pointer;
        }
        input[type="radio"]:checked + span{
            width: inherit;
            height: 20px;
            background: url(../../images/radio-checked.png) no-repeat 0 0;
        }
        input[type="radio"]:focus{
            display: none;
        }
}
&__button{
    .submit-button {
        margin: 0;
        border-radius: 6px;
    }
}
}

&__content {
    margin-top: 15px;
    &__results {
        width: 100%;
        height: 1500px;
        
    }
}


}