.loader {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    img {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 768px) {
            width: 80px;
        }
    }
}