@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.login-body-container {
    height: 100%;
    background: $white;

    .desktop-login-page {
        width: 100%;
        height: 100%;
        display: block;
        // New Design Styling Start
            .login-desktop-page {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: url('../../../shared/images/login_background.jpg') no-repeat;
                background-size: cover;
                position: relative;

                .desktop-login {
                    width: auto;
                    max-width: none;
                    display: flex;
                    .desktop-login-introduction {
                        height: 550px;
                        background-color: $blue;
                        -moz-border-top-left-radius: 15px;
                        -ms-border-top-left-radius: 15px;
                        border-top-left-radius: 15px;
                        -moz-border-bottom-left-radius: 15px;
                        -ms-border-bottom-left-radius: 15px;
                        border-bottom-left-radius: 15px;

                        .app-logo {
                            max-width: 134px;
                            margin: 15px 0 30px 20px;
                        }

                        .introduction {
                            padding: 76px;
                            text-align: center;

                            img {
                                max-width: 315px;
                            }

                            h2 {
                                margin-top: 34px;
                                color: $white;
                                @include fontFamily('Roboto', 24px, normal, 500);
                            }

                            p {
                                margin-top: 20px;
                                margin-bottom: 4px;
                                max-width: 374px;
                                color: $white;
                                @include fontFamily('Roboto', 14px, normal, 300);
                                line-height: 27px;
                            }
                        }
                    }
                    
                    .desktop-login-form {
                        width: 500px;
                        height: 550px;
                        max-height: 550px;
                        background-color: $white;
                        -moz-border-top-right-radius: 15px;
                        -ms-border-top-right-radius: 15px;
                        border-top-right-radius: 15px;
                        -moz-border-bottom-right-radius: 15px;
                        -ms-border-bottom-right-radius: 15px;
                        border-bottom-right-radius: 15px;

                        .savings-button{
                            border-left: 1px solid #124a7c;
                            padding: 18px 18px 0 19px;
                            
                        }

                        .app-logo {
                            padding-top: 12px;
                            text-align: center;

                            .candidate-mobile-header {
                                max-width: 134px;
                            }
                        }

                        .powered-by-logo {
                            padding: 30px;
                            text-align: center;
                            // position: absolute;
                            // width: 48%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                padding-left: 10px;
                                max-width: 134px;
                            }
                        }

                        .login-form-section {
                            margin-top: 38px;
                            padding: 0 60px;

                            .d-block {

                                span {
                                    color: #89969f;
                                    @include fontFamily('Roboto', 12px, normal, 400);
                                    text-transform: capitalize;
                                }

                                input {
                                    border: none;
                                    border-bottom: 1px solid $tertiary-blue;

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }

                            }

                            .input-field {
                                position: relative;

                                input::-ms-reveal {
                                    display: none;
                                }    

                                &:nth-child(2) {
                                    margin-top: 44px;
                                    
                                    input {
                                        padding-right: 30px;
                                    }
                                }

                                span.text-danger {
                                    position: absolute;
                                    bottom: -18px;
                                }

                                img {
                                    max-width: 25px;
                                    position: absolute;
                                    top: 27px;
                                    right: 0;
                                    cursor: pointer;
                                }
                            }

                            .invalid-login-error {
                                position: relative;
                                bottom: 15px;
                            }

                            .login-form-button {
                                // margin-top: 53px;
                                position: relative;
                                text-align: center;

                                button {
                                    width: 230px;
                                    padding: 15px 25px;
                                    color: #124a7c;
                                    border-radius: 50px;
                                    background: transparent;
                                    border: 2px solid $blue;
                                    @include fontFamily('Roboto', 16px, normal, 400);
                                    text-transform: capitalize;
                                    text-align: left;
                                    position: relative;
                                }

                                img {
                                    max-width: 30px;
                                    position: absolute;
                                    top: 12px;
                                    right: 90px;
                                }
                            }
                        }
                    }
                }
            }
        // New Design Styling End

            .login-header {
                padding: 30px;
                background: -webkit-linear-gradient(0deg, $blue 78%, #429ceb 100%);
                background: -moz-linear-gradient(0deg, $blue 78%, #429ceb 100%);
                background: -o-linear-gradient(0deg, $blue 78%, #429ceb 100%);
                background: -ms-linear-gradient(0deg, $blue 78%, #429ceb 100%);
                background: linear-gradient(90deg, $blue 78%, #429ceb 100%);
                display: flex;
                justify-content: space-between;
                box-shadow: 0px 0px 25.81px 3.19px rgba(0, 0, 0, 0.4);

                .login-page-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .app-logo {
                        img {
                            max-width: 180px;
                        }
                    }
                }

                .app-login-form {
                        display: flex;
                        justify-content: flex-end;

                    .login-form {
                        width: 100%;
                        position: relative;
                        
                        .invalid-login-error {
                            width:76%;
                            position: absolute;
                            padding: 8px 0;
                            margin-top: 17px; 
                            color: $primary-red;
                            @include fontFamily('Roboto', 14px, normal, 400);
                            text-align: center;
                            border: 1px solid $primary-red;
                            border-radius: 5px;

                            @media (max-width: 992px) {
                                width: 64%;
                            }

                            @media (min-width: 768px) and (max-width: 920px) {
                                width: 65%;
                            }
                        }

                        form {

                            ::-webkit-input-placeholder {
                                color: $placeholder-color;
                                @include fontFamily('Roboto', 17px, normal, 400); 
                            }

                            :-ms-input-placeholder { 
                                color: $placeholder-color;
                                @include fontFamily('Roboto', 17px, normal, 400); 
                            }

                            ::-ms-input-placeholder { 
                                color: $placeholder-color;
                                @include fontFamily('Roboto', 17px, normal, 400); 
                            }

                            ::placeholder {
                                @include fontFamily('Roboto', 17px, normal, 400); 
                                color: $placeholder-color;
                                opacity: 1; 
                            }

                            text-align: right;

                            .form-group {
                                width: 100%;
                                margin: 0 0 0 0;
                                position: relative;

                                .text-danger {
                                    position: absolute;
                                    font-size: 13px
                                }
                            }

                            div {
                                margin-right: 40px;
                                display: inline-block;
                                vertical-align: top;
                            }

                            .input-field {
                                width: 285px;

                                label {
                                    position: relative;
                                    overflow: hidden;

                                    &:before {
                                        content: url('../../../shared/images/input-border-img.png');
                                        width: 100%;
                                        position: absolute;
                                        bottom: -4px;
                                    }
                                }

                                @media (max-width: 992px) {
                                    width: 200px;
                                }

                                @media (min-width: 768px) and (max-width: 920px) {
                                    width: 147px;
                                }

                                &:nth-child(2) {
                                    margin-right: 20px;
                                }
                            }

                            input {
                                color: $white !important;
                                padding-left: 0;
                                border:none;
                                background: transparent;
                            }

                            .login-form-button {
                                button {
                                    width: 131px;
                                    padding: 10px;
                                    color: $white;
                                    background: $blue;
                                    border: 1px solid $dark-blue;
                                    @include fontFamily('Roboto', 17px, normal, 500);
                                    text-transform: uppercase;
                                    border-radius: 19px;
                                }
                            }
                        }
                    }
                }
        }

        .login-page-introduction {
            width: 100%;
            height: calc(100% - 200px);
            background: url('../../../shared/images/login-screen_BG.png') no-repeat;
            background-size: cover;
            position: absolute;
            z-index: -1;

            .wrapper {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .login-introduction-image {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    img {
                        max-height: 800px;

                        @media (max-height: 800px) {
                            height: 100%;
                        }
                    }
                }

                .login-introduction {
                    margin-right: 150px;
                    max-width: 557px;
                    text-align: center;

                    h2 {
                        margin-bottom: 28px;
                        color: #70dcf8;
                        @include fontFamily('Roboto', 37px, normal, 400);
                    }

                    p {
                        color: #70dcf8;
                        @include fontFamily('Roboto', 17px, normal, 300);
                        line-height: 30px;
                    }
                }
            }
        }
        @media (max-width: 992px) {
            display: none;
        }
    }
    .login-page-content {
        padding: 20% 0 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #ffffff;

        @media (max-width: 320px) {
            padding:0 0 7%;
        }
        @media (max-width: 412px) {
            padding: 10% 0;
        }
    }
    .mobile-login-page {
        display: none;
        // background: url('../../../shared/images/login_bg.jpg') no-repeat;
        // background-size: cover;

        .login-page {
            background: #ffffff;
            height: 100%;
            position: relative;
            .login-page-body {
                width: 100%;
                position: absolute;
                text-align: center;

                @media (max-width: 736px) and (orientation: landscape) {
                    position: static;
                    transform: none;
                }

                @media (max-height: 530px) {
                    position: static;
                    transform: none;
                }

                .mobile-app-logo {
                    padding-bottom: 15%;

                    img {
                        max-width: 195px;
                        max-height: 140px;
                    }
                }

                .powered-by-logo {
                    margin-top: 5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 195px;
                        max-height: 140px;
                    }
                    .savings-button {
                        padding-left: 20px;
                        padding-top: 20px;
                        border-left: 1px solid #124a7c;
                        .submit{
                            padding: 10px 15px;
                        }
                    }

                    @media screen and (max-width: 500px){
                        margin-top: 3%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        .savings-button {
                            border-left: none;
                        }
                    }
                    @media screen and (max-width: 440px) {
                        .savings-button{
                            margin-top: 5%;
                        }

                    }
                    @media screen and (max-width: 320px) {
                        margin: 15px 0;
                        .savings-button{
                            margin-top: 3% !important;
                        }

                    }
                    @media screen and  (max-width: 375px) {
                        .savings-button{
                            margin-top: 3% !important;
                            margin-bottom: 5%;
                        }

                    }
                }

                .login-form {
                    width: 100%;
                    position: relative;
                    
                    .invalid-login-error {
                        position: absolute;
                        bottom: 65px;
                        padding: 8px 0;
                        color: $primary-red;
                        @include fontFamily('Roboto', 14px, normal, 400);
                        border-radius: 5px;

                        @media (max-width: 550) {
                            width: 75%;
                        }
                    }

                    form {
                        padding: 0 40px;

                        ::-webkit-input-placeholder {
                            color: $placeholder-color;
                            @include fontFamily('Roboto', 17px, normal, 400); 
                        }

                        :-ms-input-placeholder { 
                            color: $placeholder-color;
                            @include fontFamily('Roboto', 17px, normal, 400); 
                        }

                        ::-ms-input-placeholder { 
                            color: $placeholder-color;
                            @include fontFamily('Roboto', 17px, normal, 400); 
                        }

                        ::placeholder {
                            @include fontFamily('Roboto', 17px, normal, 400); 
                            color: $placeholder-color;
                            opacity: 1; 
                        }

                        .form-group {
                            width: 100%;
                            margin: 0 0 0 0;
                            position: relative;

                            .text-danger {
                                position: absolute;
                                font-size: 13px
                            }
                        }

                        .input-field {
                            margin-bottom: 50px;
                            position: relative;
                            
                            label {
                                position: relative;
                                //border-bottom: 1px solid rgba(64, 164, 211, 0.5);
                                overflow: hidden;
                                color: #89969f;
                                @include fontFamily('Roboto', 12px, normal, 400);
                                text-transform: capitalize;
                            }

                            &:nth-child(2) {
                                margin-bottom: 62px;

                                input {
                                    padding-right: 30px;
                                    position: relative;
                                }

                                img {
                                    max-width: 30px;
                                    position: absolute;
                                    top: 15px;
                                    right: 0;
                                }
                            }
                        }

                        input {
                            padding-left: 0;
                            border:none;
                            background: transparent;
                            border-bottom: 1px solid rgba(64, 164, 211, 0.5);

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        .login-form-button {
                                // margin-top: 53px;
                                position: relative;
                                display: inline-block;

                                button {
                                    width: 230px;
                                    padding: 15px 25px;
                                    color: #124a7c;
                                    border-radius: 50px;
                                    background: transparent;
                                    border: 2px solid $blue;
                                    @include fontFamily('Roboto', 16px, normal, 400);
                                    text-transform: capitalize;
                                    text-align: left;
                                    position: relative;
                                }

                                img {
                                    max-width: 30px;
                                    position: absolute;
                                    top: 12px;
                                    right: 20px;
                                }
                            }
                    }
                }
            }
        }

        .app-footer {
            background: #ffffff;
            position: fixed;
            h3 {
                @include fontFamily('Roboto', 14px, normal, 400);
            }

            @media (max-width: 736px) and (orientation: landscape) {
                position: static;
            }

            @media (max-height: 530px) {
                position: static;
            }
        }

        @media (max-width: 992px) {
            height: 100%;
            display: block;
        }
    }
}