@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

button {
    padding: 10px;
    border: 0;
    border-radius: 6px;
    box-shadow: none;
    outline: 0;
    color: $white;
    text-transform: uppercase;
    cursor: pointer;
    flex-grow: 1;
    flex-basis: 0;

    &.submit {
        background-color: $blue;
        border-radius: 24px;
        @include fontFamily('Roboto', 13px, normal, 500);
    }
    
    &.position-add-button {
        width: 105px;
        position: relative;
        text-align: right;
        padding-right: 15px;
    }
    &.add-submit {
        padding: 15px 20px;
        background-color: $blue;
        border-radius: 24px;
        @include fontFamily('Roboto', 13px, normal, 500);
    }
    &.contact-add-button{
        width: 156px;
        position: relative;
        text-align: right;
        padding-right: 15px;
    }

    &.distribution-add-button {
        width: 190px;
        position: relative;
        text-align: right;
        padding-right: 15px;
    }

    &.default {
        background-color: $white;
        color: $blue;
        border: 1px solid $blue;
    }

    &:focus,
    &:hover {
        outline: 0 !important;
        box-shadow: none;
    }

    &:disabled {
        background: $primary-grey;
        cursor: not-allowed;
        border: none;
    }
}