@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';
.saving-footer {
    background: #124a7c;
    p {
        // color: $white;
        line-height: 25px;
        @include fontFamily('Roboto', 12px, normal, 400);

        span {
            font-weight: 500;
        }
    }
    .desktop-footer {
        display: flex;
        justify-content: center;
        align-items: center;


        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobile-footer {
        display: none;

        @media (max-width: 768px) {
            display: block;
        }
    }
}