@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.app-footer {
    padding: 11px 0 9px 0;
    background-color: $white;
    clear: both;
    text-align: center;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    border-top: 1px solid rgba(0,0,0,.10);

    h3 {
        color: #666;
        @include fontFamily('Roboto', 12px, normal, 300);
        text-transform: capitalize;
    }
}