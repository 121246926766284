.user-list-container {
  .list {
    padding: 5px 10px;
    display: block;
  }
}
.user-list-card {
  min-width: 250px;
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  border-radius: 9px;
  .card-header {
    background-color: #ffffff;
    padding: 0;
  }
  .card-body {
    background-color: #ffffff;
    padding: 0;
  }
  .card-footer {
    display: block;
    margin: 3px;
    padding: 0px;
    background-color: #fff;
  }
  &__border {
    border: 1px solid #124a7c;
  }
  &:hover {
    border: 1px solid #124a7c;
    box-shadow: 0px 3px 20px #e3e3e3;
  }
}

@media screen and (max-width: 992px) {
  .user-list-container {
    width: 100%;
  }
}
