.submit-requirements {
  border-radius: 10px;
  padding: 15px;
  .error-field {
    border: 1px solid red !important;
  }
  .fields-msg {
    color: #707070;
    font-size: 12px;
    margin-bottom: 20px;
    span {
      color: red;
    }
  }
  .resume-field {
    cursor: pointer;
    height: 40px !important;
    margin-bottom: 35px !important;
    .form-fields .help-text-file-upload {
      margin-top: 10px !important;
    }
    .form-fields .allowed-files-error {
      margin-top: 5px !important;
    }
  }
  .error-msg {
    color: red;
    font-size: 13px;
    padding-bottom: 8px;
  }
  &__heading {
    padding-bottom: 10px;
    font-weight: 500;
    color: #015376;
    text-transform: capitalize;
    h6 {
      font-size: 20px !important;
      margin: 0;
      font-family: "Roboto" !important;
    }
  }
  .fieldset {
    width: 100%;
    border-radius: 6px;
    background: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid #707070;
    border-radius: 6px;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 27px;


    .label {
      position: absolute;
      top: -8px;
      z-index: 1;
      left: 20px;
      line-height: 1;
      padding: 0 5px;
      font-size: 13px;
      color: #707070;
      background: white;

      span {
        color: red;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
    .form-control {
      border-bottom: none !important;
    }
    .file-upload .file-details {
      background: #fff;
      margin-bottom: 4px;
      padding: 0px 12px !important;
    }
  }
  &__details {
    .submit-btn-div {
      text-align: center;
    }
    form {
      padding: 0px;
    }
    label {
      display: flex;
      margin-bottom: 5px;
    }
    input {
      margin-top: 7px;
      padding: 0 25px;
      margin-bottom: 5px;
      height: 25px !important;
      font-size: 14px !important;
    }
    #email {
      border: none;
      box-shadow: none;
    }
    .attachement-img {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    span.text-danger.d-block {
      border-top: 0;
      position: absolute;
      top: 30px;
      left: 0;
      padding: 0;
      font-size: 10px;
      margin-top: 8px;
    }
    .notes-fieldset {
      height: 70px;
      .form-fields .form-group {
        .d-block textarea {
          height: 50px;
        }
        .text-danger {
          top: 56px;
        }
      }
    }
    textarea {
      font-size: 14px;
      margin-top: 7px;
      padding-top: 5px;
      padding-left: 25px;
      padding-right: 25px;
      font-family: "Roboto";
    }
    textarea:active,
    textarea:hover {
      outline: none;
      box-shadow: none;
    }
    .form-control:focus {
      box-shadow: none !important;
      border: none !important;
    }
    input::-ms-clear {
      display: none;
    }
    .placeholder {
      padding-top: 3px;
      padding-left: 25px;
      line-height: 10px;
      font-size: 12px;
    }
    .file-upload {
      top: 5px;
      border-bottom: none;
    }
    .file-upload .file-scan {
      bottom: -23px;
    }
    .submit-btn {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 40px;
      text-align: center;
      border-radius: 16px;
      text-transform: capitalize;
      background-color: #015376;
    }
  }
}
