@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.dashboard {
  position: relative;
  // margin: 35px;
  // padding: 33px;
  // background-color: $add-bg-color;
  margin-bottom: 30px;
  .title {
    display: none;

    @media (max-width: 768px) {
      display: block;
      background: $white;

      h2 {
        padding: 20px;
        @include fontFamily("Roboto", 24px, "normal", 400);
        text-transform: uppercase;
      }
    }
  }

  h2 {
    padding: 0px 20px 15px 20px;
    color: $blue;
    @include fontFamily("Roboto", 30px, normal, 300);
    text-transform: uppercase;
  }

  h3 {
    // padding: 10px 20px 15px 20px;
    color: $black;
    @include fontFamily("Roboto", 30px, normal, 300);
    text-transform: capitalize;

    &.welcome-user {
      padding: 30px 0 10px 20px;
      color: $blue;

      @media (max-width: 768px) {
        padding: 15px 20px 0;
        @include fontFamily("Roboto", 24px, normal, 300);
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) and (orientation: landscape) {
    padding: 0 0 60px 0;
  }
}

.chart-no-padding-right {
  padding-right: 0 !important;

  @media (max-width: 992px) {
    padding-right: 15px !important;
  }
}

.chart-padding-left {
  padding-left: 20px !important;

  @media (max-width: 992px) {
    padding-left: 15px !important;
  }
}

.highcharts-credits {
  display: none;
}

.chart-card {
  margin-top: 20px;
  box-shadow: 0px 0px 7.92px 0.08px rgba(0, 0, 0, 0.15);
  background-color: $white;
  border-radius: 10px;
  overflow: auto;
  cursor: pointer;
  .css-1kwsdf3,
  .css-wf96y3,
  .css-h5dbrh {
    border: none;

    &:hover {
      border: none;
    }
    @media screen and (max-width: 600px) {
      padding-top: 11px;
    }
  }

  .chart-card-title {
    display: flex;
    padding-left: 27px;
    align-items: center;
    img {
      height: auto;
      // width: 10px;
      margin-right: 5px;
    }
    h2 {
      padding: 0px !important;
    }
  }
  .chart-card-dropdown {
    .dropdown {
      .position-relative {
        .d-block {
          .css-15k3avv {
            margin-top: -3%;
          }
        }
      }
    }
  }
  .css-37p3qo {
    padding-left: 5px;
    color: #89969f;
    @include fontFamily("Roboto", 12px, normal, 400);
  }

  .chart-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      padding: 20px 27px;
      color: $light-black;
      @include fontFamily("Roboto", 18px, normal, 700);
      text-transform: capitalize;

      @media screen and (max-width: 500px) {
        @include fontFamily("Roboto", 14px, normal, 700);
      }
    }

    .dropdown {
      width: 160px;
      padding: 0 27px 0 0;

      .css-sd4rsn {
        border: 0;

        &:hover {
          border: 0;
        }
      }

      .css-fe4xcx {
        border: 0;

        &:hover {
          border: 0;
        }
      }

      .position-relative {
        .d-block {
          .css-0 {
            .css-h5dbrh {
              .css-1hwfws3 {
                .css-1nd2pan {
                  position: relative !important;
                  top: 0 !important;
                  padding-top: 10px;
                  // z-index: 1;
                }
              }
              .css-1wy0on6 {
                border: none;
              }
            }
          }
        }
      }

      .css-1hwfws3 {
        .css-18alh78 {
          padding-left: 0;
          color: $dark-grey;
          @include fontFamily("Roboto", 12px, normal, 400);
          text-transform: capitalize;
        }
      }
    }
  }

  p.pie-chart-total-count {
    color: $dark-grey;
    @include fontFamily("Roboto", 14px, normal, 400);
  }
}

.dashboard-top-positions-list {
  height: 400px;
  overflow: auto;
  counter-reset: item;

  li {
    position: relative;
    padding: 20px 15px;
    border-bottom: 1px solid $grey;
    cursor: pointer;
    counter-increment: item;

    h3 {
      display: inline-block;
      color: $black;
      @include fontFamily("Roboto", 16px, normal, 400);
      line-height: 25px;
      text-transform: capitalize;
      max-width: 275px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;

      &:before {
        margin-right: 5px;
        content: counter(item) ".";
        @include fontFamily("Roboto", 16px, normal, 400);
      }
    }

    .top-position-div {
      display: flex;
      justify-content: space-between;

      p {
        float: right;
        color: $dark-grey;
        @include fontFamily("Roboto", 14px, normal, 400);
        text-transform: capitalize;

        @media (max-width: 585px) {
          padding-left: 18px;
          float: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &:hover {
      background-color: rgba(3, 168, 242, 0.1);
    }
    .tooltip-list {
      position: absolute;
      top: 0;
      left: 42%;
      z-index: 100;
      display: none;
      background: black;
    }

    &:hover {
      .tooltip-list {
        display: block;
      }
    }
  }
}

.tooltip .arrow {
  display: none !important;
}

.position-tooltip {
  li {
    width: 160px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;

    h4 {
      color: $white;
      @include fontFamily("Roboto", 13px, normal, 400);
      text-align: left;
    }

    p {
      color: $white;
      @include fontFamily("Roboto", 13px, normal, 500);
      line-height: 25px;
    }
  }
}

.dashboard-application-ratio-proportion {
  display: flex;
  justify-content: space-between;

  li {
    width: 18%;
    min-width: 195px;
    margin-top: 20px;
    margin-right: 20px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 1301px) {
      width: 19%;
    }

    @media (max-width: 1100px) {
      width: 31%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (max-width: 992px) {
      width: 48%;

      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 20px;
      }
    }

    @media (max-width: 585px) {
      width: 100%;
    }
  }

  @media (max-width: 1100px) {
    display: block;
  }
}

.show-accordion-body {
  display: block;
}
.hide-accordion-body {
  display: none;
}
.remove-margin {
  margin-bottom: 0px !important;
}
