@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.input-field {
  margin-bottom: 35px;
  padding-left: 0;
  label {
    font-weight: 500;
    display: block;

    span {
      text-transform: uppercase;
      @include fontFamily("Roboto", 12px, normal, 500);

      &.text-danger {
        @include fontFamily("Roboto", 11px, normal, 500);
        text-transform: none;
      }

      &.required-field {
        color: $primary-red;
      }
    }
  }

  .horizontal-radio-buttons {
    .form-check {
      margin-right: 52px;

      @media (max-width: 585px) {
        width: 46%;
        padding: 15px 0 0 0;
        margin-right: 10px;
        vertical-align: top;
      }
    }
  }

  .radio-input-textbox {
    width: 125px;
    margin-right: 20px;
    display: inline-block;

    @media (max-width: 585px) {
      width: 49%;
      padding: 0;
      margin: 0;
    }

    input {
      padding-left: 0;
    }

    .text-danger {
      font-size: 11px;
    }
  }

  .label-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pay-rate-range {
    width: 200px;
    position: absolute;
    top: 55px;
    @media (max-width: 585px) {
      width: 100%;
      padding-right: 20px;
    }

    .amount-range {
      width: 70px;
      display: inline-block;

      @media (max-width: 585px) {
        width: 45%;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

::-webkit-input-placeholder {
  color: #89969f;
  @include fontFamily("Roboto", 15px, normal, 300);
}

:-ms-input-placeholder {
  color: #89969f;
  @include fontFamily("Roboto", 15px, normal, 300);
}

::-ms-input-placeholder {
  color: #89969f;
  @include fontFamily("Roboto", 15px, normal, 300);
}

::placeholder {
  @include fontFamily("Roboto", 15px, normal, 300);
  color: #89969f;
  opacity: 1;
}

input,
textarea {
  height: 39px;
  max-height: 160px;
  overflow: auto;
  border: none;
  border-bottom: 1px solid #bdd6ee;
  resize: none;

  &:focus {
    outline: none;
    // box-shadow: none;
  }
}
