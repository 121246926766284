@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.card-style {
  background-color: $tertiary-grey;
  border-bottom: none;
}

.card {
  // margin:15px 0;
  background-color: $tertiary-grey;
  border: none;
  border-radius: 12px;

  a {
    text-decoration: none;
  }

  .card-header {
    padding-top: 22px;
    padding-bottom: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    @extend .card-style;

    @media (max-width: 992px) {
      background: $white;
    }
  }

  .card-body {
    padding: 0 20px;
    color: $dark-grey;
    @extend .card-style;

    .card-body-group {
      padding: 10px 0 0;

      &:first-child {
        padding-top: 10px;
      }

      .card-body-label {
        color: rgba(0, 0, 0, 0.8);
        @include fontFamily("Roboto", 14px, normal, 400);
      }

      .card-body-value {
        color: rgba(0, 0, 0, 0.8);
        @include fontFamily("Roboto", 14px, normal, 500);
      }
    }

    @media (max-width: 992px) {
      background: $white;
    }
  }

  .card-footer {
    display: flex;
    border-top: none;
    color: $dark-grey;
    margin-bottom: 8px;
    align-items: center;
    background-color: $tertiary-grey;
    justify-content: space-between;

    .card-footer-group {
      display: flex;
      align-items: center;

      .card-footer-label {
        color: rgba(0, 0, 0, 0.8);
        @include fontFamily("Roboto", 15px, normal, 400);
      }

      .card-footer-value {
        color: rgba(0, 0, 0, 0.8);
        @include fontFamily("Roboto", 15px, normal, 400);

        .status {
          width: 95px;
          text-align: right;
        }

        .display-date {
          padding-left: 5px;

          span {
            font-weight: 500;
          }
        }
      }
    }

    @media (max-width: 992px) {
      background: $white;
    }
  }

  &.career-card {
    border: 1px solid $grey;
    margin: 15px 0 5px 0;
    background-color: $white;
    cursor: pointer;
    // overflow: hidden;
    box-shadow: 0px 0px 7.68px 0.32px rgba(0, 0, 0, 0.25);

    .card-header {
      padding: 15px 25px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;

      .card-header-title {
        h2 {
          padding-bottom: 8px;
          position: relative;
          color: $black;
          @include fontFamily("Roboto", 24px, normal, 500);

          &:after {
            content: "";
            width: 16px;
            height: 16px;
            background: url("../../images/icon-right-arrow.png") no-repeat;
            position: absolute;
            top: 6px;
            right: -30px;
          }

          @media (max-width: 768px) {
            max-width: 400px;
            word-break: break-word;
          }

          @media (max-width: 585px) {
            max-width: 250px;
          }
        }
      }
    }

    .card-body {
      padding: 0 25px 5px 25px;
      background-color: $white;

      .card-body-group {
        .card-body-value {
          position: relative;
          display: block;
          @include fontFamily("Roboto", 16px, normal, 400);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .show-work-icon {
        padding-left: 34px;
        position: relative;
        display: block;
        @include fontFamily("Roboto", 16px, normal, 400);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:before {
          content: "";
          width: 20px;
          height: 17px;
          background: url("../../images/icon-work.png") no-repeat;
          position: absolute;
          left: 0;
        }
      }

      .show-industry-icon {
        padding-left: 34px;
        position: relative;
        display: block;
        @include fontFamily("Roboto", 16px, normal, 400);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:before {
          content: "";
          width: 20px;
          height: 17px;
          background: url("../../images/icon-industry.png") no-repeat;
          position: absolute;
          left: 0;
        }
      }
    }

    .card-footer {
      padding: 0 30px 0px;
      margin: 5px 0;
      background-color: $white;
    }

    &:hover {
      border: 1px solid $blue;
      box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.08);
    }
  }

  @media (max-width: 992px) {
    background: $white;
  }
}
