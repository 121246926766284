@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';
.distributed-title {
    margin-left: 25px; 
    margin-right: 28px;
    padding: 34px 26px 0 0;
    border-bottom: 1px solid #12497C;
    h3 {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 19px;
    color: #12497C;
    font-family: "Roboto";
    font-weight: 700;
    }
    }