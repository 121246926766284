@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.position-details {
    .desktop-status-details {
        display: block;
        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobile-status-details {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }

    .position-main-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            display: block;
            padding-top: 0 !important;
        }

        .position-title {
            width: 80%;
            display: flex;
            align-items: center;

            @media (max-width: 1024px) {
                display: block;
            }

            h2 {
                color: $black;
                @include fontFamily('Roboto',24px,'normal',500);
                text-transform: none;

                @media(max-width: 1024px) {
                    max-width: 450px;
                    word-break: break-all;
                }

                @media (max-width: 768px) {
                    margin-top: 25px;
                }
            }

            .display-date {
                margin-left: 40px;
                color: $dark-grey;
                @include fontFamily('Roboto',12px,'normal',400);
                
                @media (max-width: 1024px) {
                    margin: 10px 0 0 18px;
                }
            }
        }

        .position-edit-icon {
            cursor: pointer;
        }
    }

    .drop-down {
        width: 115px;
    }

    ul.filter {
        padding: 33px 0 0 0px;
    }

    .custom-date-range {
        padding: 0 0px 20px;
    }

    .experience-range-filter {
        padding: 0 0 40px !important;
    }

    @media (max-width: 768px) {
        height: 100%;

        .details-page {
            height: 100%;
        }
    }
}

.more-details {
    .details-header {
        margin-bottom: 30px !important;
    }
    .more-details-sections {
        height: 0;
        overflow: hidden;

        &.show-more-details {
            height: auto;
            @include animation('slide-down 0.5s forwards');
        }
        &.hide-more-details {
            height: 0;
            @include animation('slide-up 0.5s forwards');
        }
    }
    .more-details-trigger {
        width: 100%;
        cursor: pointer;
        position: relative;
        text-align: center;

        &:before {
            width: 100%;
            content: ' ';
            border-bottom: 1px solid #e6e6e6;
            position: absolute;
            left: 0;
            top: 50%;
        }

        img {
            padding: 0 13px;
            background: $white;
            position: relative;
            z-index: 1;
        }
    }
}

.more-detail-header {
    width: 100%;
    text-transform: none;
    color: $blue;
    @include fontFamily('Roboto',18px,'normal',700);
}

.tags {
    span {
        padding: 8px 12px;
        margin: 0 12px 12px 0;
        background: #c3e2ff;
        border-radius: 5px;
        color: $blue;
        display: inline-block;
        @include fontFamily('Roboto',13px,'normal',400);
    }
}

.education-experience-description {
    p {
        margin-bottom: 12px;

        &:last-child {
            margin: 0;
        }
    }
}

.social-share {
    display: flex;
    align-items: center;

    .platform {
        width: 227px;
        padding: 15px;
        margin-right: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        cursor: pointer;

        .platform-logo {
            display: flex;
            align-items: flex-end;

            img {
                display: inline-block;
                margin-right: 11px;
            }

            span {
                display: inline-block;
                color: #89969f;
                @include fontFamily('Roboto',12px,'normal',400);
                vertical-align: text-top;
            }
        }

        a {
            margin-left: 30px;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        display: block;
    }
}

.right-divider {
    padding-right: 10px !important;
    margin-right: 10px;
    border-right: 1px solid $tertiary-blue;
}

.details-page {
    background: #fff;

    .page-title {
        padding: 40px;

        h2 {
            color: $blue;
            @include fontFamily('Roboto',30px,'normal',400);
            text-transform: uppercase;
        }

        @media(max-width: 768px) {
            padding: 15px 18px;
        }
    }

    .content-title-header {
        padding: 20px 32px;

        .title-hide {
            margin-bottom: 10px;
        }

        .content-title {
            display: block;

            .sub-header-text {
                .sub-header{
                    color: #666;
                    margin-bottom: 8px;
                    display: block;
                    .display-date {
                        margin: 0;
                        span {
                            color: #666;
                            @include fontFamily('Roboto',14px,'normal',400);
                        }
                    }

                    @media (max-width: 768px) {
                        margin: 0 0 8px 0;
                        display: block;
                        border: none;

                        .show-clock {
                            &:before {
                                width: 0;
                                margin: 0;
                                background: none;
                            }
                        }
                    }
                }
            }

            h2 {
                margin-bottom: 10px;
                color: $blue;
                @include fontFamily('Roboto', 24px, 'normal', 400);
                display: inline-block;

                @media (max-width: 768px) {
                    margin-bottom: 15px;
                    display: block;
                    border: none;
                }
            }

            h3 {
                padding-right: 10px;
                margin: 0px 10px 10px 0;
                color: $black;
                @include fontFamily('Roboto', 18px, 'normal', 400);
            }

            .action-buttons {
                margin-top: 25px;
                display: flex;
                align-items: center;

                a {
                    width: 80px;
                    margin-left: 15px;
                    padding: 10px 0;
                    display: inline-block;
                    color: $dark-blue;
                    border: 2px solid $dark-blue;
                    border-radius: 20px;
                    @include fontFamily('Roboto', 14px, 'normal', 500);
                    text-transform: uppercase;
                    text-align: center;
                }

                .drop-down {
                    width: 100px;
                    display: inline-block;
                    vertical-align: bottom;

                    .css-1whs5fm,.css-1ohxolz {
                        background-color: $green;
                        border: 1px solid $green;
                    }
                }

                &.open {
                    .drop-down {
                       .css-1whs5fm,.css-1ohxolz {
                            background-color: $green;
                            border: 1px solid $green;
                        } 
                    }
                }

                &.closed {
                    .drop-down {
                       .css-1whs5fm,.css-1ohxolz {
                            background-color: $primary-grey;
                            border: 1px solid $primary-grey;
                        } 
                    }
                }

                &.draft {
                    .drop-down {
                       .css-1whs5fm,.css-1ohxolz {
                            background-color: $orange;
                            border: 1px solid $orange;
                        } 
                    }
                }
            }
        }

        @media (max-width: 768px) {
            padding: 25px 18px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    .tabs-section {
        margin: 0;
        background: #fff;

        @media (max-width: 440px) {
            padding-bottom: 20px;
        }

        .tabs {
            
            h3 {
                @media (max-width: 768px) {
                    .tab-without-count {
                        padding-left: 0;
                    }
                }

                @media (max-width: 440px) {
                    .tab-without-count {
                        padding-right: 0;
                    }
                }
            }
            @media (max-width: 768px) {
                width: 95%;
                margin: 0 auto;
            }
            @media (max-width: 600px){
                width: 95%;
                margin: 0 auto;
            }
            @media (max-width: 350px) {
                width: 98%;
                margin: 0 auto;
            }

        }

        .tab-body {
            padding-bottom: 15px;
            padding-left: 40px;
            padding-right: 40px;

            @media (max-width: 768px) {
                width: 95%;
                margin: 0 auto;
                padding: 0;

                .more-details {
                    padding-top: 15px;
                }
            }

            @media (max-width: 440px){
                width: 90%;
                margin: 0 auto;
            }
        }
        .desktop-applicants {
            margin-top: 20px;
        }
    }

    .applications-tab, .talentpool-tab {
        .list {
            padding: 0 0 20px 0;
            
            @media(max-width: 440px) {
                border: 1px solid $grey;
            }
        }

        .desktop-list {
            .list-header {
                display: none;
            }
        }

        .search-filter-section {
            display: block;
            @media (max-width: 768px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            .desktop-applicants {
                display: none;
            }
        }
    }

    @media (max-width: 768px) {
        margin: 0;
    }
}

.tab-header-text {
    padding: 0 40px 0px;
    .tab-header-small-text {
        display: flex;
        align-items: center;

        .small-text {
            color: $blue;
            cursor: pointer;
            @include fontFamily('Roboto',12px,'normal',500);
            text-transform: capitalize;
        }

        .count {
            padding: 3px;
            margin-left: 5px;
            color: $white;
            background: #0088ff;
            cursor: pointer;
            border-radius: 10px;
            @include fontFamily('Roboto',12px,'normal',700);
        }
    }

    h3 {
        color: $blue;
        cursor: pointer;
        @include fontFamily('Roboto',18px,'normal',500);
        text-transform: capitalize;
    }
    @media (max-width: 600px) {
        padding: 0 20px 0px;
    }
    @media (max-width: 440px) {
        padding: 0 0 0 15px;
    }
    @media (max-width: 350px) {
        padding: 0 0 0 5px;
    }
}

.tabBodyTransparent {
    background: #eee;
}

.applications-tab {
    .search-filter-section {
        display: none;
    }
}

.position-details-no-result-found {
    padding: 25px;
    text-align: center;
}