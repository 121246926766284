//**************** Chatbot Job List css ******************//
.view-more-jobs-container {
  cursor: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  .or-text {
    padding: 3px 0;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
  }
  .submit-button,
  .cancel-button {
    margin: 0;
    width: 150px;
    font-size: 11px;
    padding: 5px 10px;
    border: 1px solid #015376 !important;
  }
  .submit-button {
    background: #015376;
  }
  .cancel-button {
    color: #015376 !important;
    margin-top: 7px;
    background: #fff !important;
  }
}

//**************** Chatbot Card View css ******************//

.chatbot-card-view {
  border-radius: 10px;
  margin: 15px 15px 15px 5px;
  border: 1px solid rgb(182, 181, 181);
  box-shadow: 0px 0px 1.8px 0 rgba(0, 0, 0, 0.5);
}

//**************** Chatbot Horizontall scroller ******************//

.chatbot-job-list {
  padding-bottom: 25px;
}
.chatbot-horizontal-scroller {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar-track {
    background: #909091;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    filter: drop-shadow(0px 2px 0px #124a7c);
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    -webkit-box-shadow: inset 0 0 30px #124a7c;
    filter: drop-shadow(0px 2px 0px #124a7c);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #124a7c;
  }
}

//**************** Chatbot UI css ******************//

.chatbot-opened {
  width: 360px;
  overflow: hidden;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.chatbot-minimized {
  height: 60px;
}
.header-padding {
  height: 70px !important;
  padding: 0px 20px 10px !important;
}
.chatbot-closed {
  padding: 12px;
  border-radius: 35px;
  background-color: #015376;
}
.chatbot-ui-components {
  height: 375px;
  overflow-y: auto;
  scroll-behavior: revert !important;
  -webkit-overflow-scrolling: touch;
  padding: 15px 23px;
}
.chatbot-ui-container {
  position: fixed;
  bottom: 70px;
  right: 0;
  z-index: 10;
  cursor: pointer;
  margin-right: 96px;
  font-family: "Roboto" !important;
  box-shadow: 0 0 7.81px 0 rgba(0, 0, 0, 0.4);

  &__chatbot-header {
    padding: 10px 20px;
    margin-bottom: 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #015376;
    .chatbot-header-icons {
      display: flex;
      align-items: center;
      .icon-minimize::before {
        position: relative;
        top: -8px;
      }
    }
    .minimize-icon,
    .cross-icon {
      cursor: pointer;
      margin-left: 10px;
    }
    .minimize-icon {
      font-size: 30px;
    }
    .cross-icon {
      font-size: 25px;
    }
    &__title {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      .green-dot {
        position: absolute;
        top: 10px;
        left: 35px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #0fcc45;
      }
      span {
        font-size: 22px;
        padding-left: 10px;
      }
    }
  }
  .chatbot-image {
    width: 40px;
    vertical-align: middle;
  }
  .star-icomo {
    font-size: 18px;
    vertical-align: top;
  }
}
.display-none {
  display: none;
}

@media screen and (max-width: 992px) {
  .chatbot-ui-container {
    bottom: 45px;
    margin-right: 85px;
  }
}

@media screen and (max-width: 600px) {
  .chatbot-opened {
    width: 100%;
    margin-right: 0;
    bottom: 0;
    top: 80px;
  }
  .chatbot-closed {
    bottom: 115px;
    margin-right: 10px;
  }
  .chatbot-ui-components {
    height: 83%;
  }
}

@media screen and (max-width: 360px) {
  .chatbot-ui-components {
    height: 80%;
  }
}
