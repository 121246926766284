.list-verical-scroller-bar {
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 10px;
  background-color: white;

  &::-webkit-scrollbar-track {
    background-color: rgba(189, 189, 189, 0.3);
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(221, 219, 219);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(82, 81, 81);
  }
}
