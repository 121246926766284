$white: #fff;
$black: #000;
$grey: #dee4e9;
$purple: #4f5cff;
$red: #a94442;
$green: #2cc97e;
$light-grey: #f5f5f5;
$dark-grey: #89969f;
$primary-white: #ebebeb;
$primary-black: #414141;
$secondary-black: #404040;
$tertiary-black: #404041;
$light-green: #cdebde;
$primary-grey: #8b8a9c;
$orange: #f29e59;
$light-orange: #fceadb;
$secondary-grey: #dddde2;
$tertiary-grey: #f1f2f4;
$primary-red: #f10000;
$primary-purple: #7f61f3;
$secondary-purple: #efecfe;
$secondary-red: #e40c0c;
$light-red: #fce6e6;
$light-blue: #5a98cf;
$black-grey: #a4b4bf;
$tertiary-red: #f10000;
$dark-black: #cfd4d7;
$tertiary-purple: #eaebff;
$light-black: #333;
$blue: #124a7c;
$primary-blue: #3073b1;
$secondary-blue: #a3c8e9;
$tertiary-blue: #bdd6ee;
$dark-blue: #083761;
$placeholder-color: #c5c5c5;
$blur-black: #707070;
$blue-green: #40a4d3;
$add-bg-color: #f4f8fc;

:root {
  --background-color: #fff;
  --font-primary: #333;
  --font-secondary: #666;
  --filter-font-title: #000;
  --dark-grey: #999;
  --primary-color: #124a7c;
}
