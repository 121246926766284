@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.page-not-found {
    .page-not-found-content {
        text-align: center;

        img {
            max-height: 450px;
            height: auto;
            padding: 30px 0;
        }

        p {
            @include fontFamily('Roboto', 29px, normal, 300);

            @media(max-width: 768px) {
                @include fontFamily('Roboto', 20px, normal, 300);
            }
        }
    }
}