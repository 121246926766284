@import "../../../shared/style/variables";
@import "../../../shared/style/_mixins.scss";

.tag {
  height: 30px;
  padding: 8px 20px 0;
  border: 1px solid $tertiary-blue;
  border-radius: 20px;
  display: inline-block;
  background-color: rgba(205, 235, 222, 0);
  // color: $dark-grey;
  @include fontFamily("Roboto", 12px, normal, 500);
  text-transform: capitalize;

  &::after {
    content: "\2715";
    margin-left: 14px;
    // color: $dark-grey;
    @include fontFamily("Roboto", 12px, normal, 500);
  }

  a {
    padding-left: 16px;
    display: inline-block;
    vertical-align: middle;
    @include fontFamily("Roboto", 18px, normal, 500);
    cursor: pointer;
  }
}
