@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.candidate-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  min-height: 67px;
  background: $white;

  .hide-block {
    display: none;
  }

  img {
    max-width: 85px;
  }

  &__menu {
    h2 {
      margin-left: 15px;
      color: $primary-blue;
      @include fontFamily("Roboto", 28px, normal, 400);
    }
    .menubar {
      display: flex;
      align-items: center;
      img {
        width: 80%;
      }
      .hamburger {
        display: block;
        padding-top: 4px;
        padding-right: 5px;
      }
      .hamburger-menu {
        width: 25px;
        height: 3px;
        margin-bottom: 4px;
        background-color: $blue;
      }
    }

    .vendor-product-logo {
      display: flex;
      align-items: center;

      .vendor-logo {
        padding: 8px 20px;

        div {
          background-color: #fff;
          padding: 10px;
          border-radius: 10px;
        }

        img {
          max-height: 56px;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .candidate-mobile-header {
    padding: 0 15px;
    &__menu {
      .menubar {
        h2 {
          font-size: 22px;
        }
      }
    }
  }
}
.candidate-info-mobile {
  display: flex;
  align-items: center;

  &__dropdown {
    display: flex;
    margin-left: 20px;
  }
  &__notifications {
    position: relative;
    margin-left: 5px;
    &__count {
      width: 15px;
      height: 15px;
      padding-top: 1px;
      position: absolute;
      text-align: center;
      top: 4px;
      right: 4px;
      color: #fff;
      font-weight: 600;
      font-size: 11px;
      background: red;
      border-radius: 10px;
    }
    .bell-icon {
      font-size: 26px !important;
    }
  }

  .middle-line {
    width: 1px;
    height: 45px;
    margin: 0 10px 0 10px;
    background: lightgray;
  }
}
// @media (max-width: 992px) {
//     .application-custom-notifiaction-dropdown-content{
//         &__list {
//             &__options {
//                 top: 26px;
//                 left: -45px;
//             }
//             &:before {
//                 top: 16px;
//                 right: 1px;
//             }
//         }
//         &__title{
//             padding-top: 0px;
//         }
//     }
// }
