.user-list-Card-body-container {
  font-weight: 200;
  padding: 0 20px 15px;
  &__container {
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  &__title {
    color: #6b6b6b;
  }
  &__contain {
    color: #000000;
    padding-left: 4px;
  }
}

// @media screen and (max-width: 1200px) {
//   .user-list-Card-body-container {
//     &__container {
//       justify-content: flex-start;
//     }
//     &__padding-top {
//       padding-top: 5px;
//     }
//   }
// }
// @media screen and (min-width: 600px) and (max-width: 768px) {
//   .user-list-Card-body-container {
//     &__top-div {
//       display: flex;
//     }
//     &__container {
//       justify-content: flex-start;
//     }
//     &__divider {
//       display: block;
//       margin: 0 20px;
//     }
//     &__padding-top {
//       padding-top: 0;
//     }
//   }
// }
