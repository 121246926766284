.user-list-Card-header-container{
    padding: 15px 1.25rem 0px ; 
    &__name{
        font-size: 24px;
        font-weight: 700;
        text-transform: capitalize;
    }
    &__position{
        font-size: 16px;
        color: #000000;
        padding-top: 10px;
        font-weight: 500;
        text-transform: uppercase;
    }
}