.chatbot-location-input {
  display: flex;
  padding: 10px;
  color: #4f4f4f;
  margin-bottom: 15px;
  background: #e4e6ec;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  form {
    padding: 0;
    width: 100%;
    position: relative;
  }
  .city-name {
    display: flex;
    margin-bottom: 10px;
    .tag-city-name {
      display: flex;
      width: 80px;
      height: 30px;
      margin-right: 5px;
      border: 1px solid #bdd6ee;
      border-radius: 20px;

      .tag {
        width: 62px;
        overflow: hidden;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px 8px 0;
        border: none;
        border-radius: 0;
        font-size: 13px;

        &:after {
          content: "";
        }
      }

      &:after {
        content: "\2715";
       
        font-family: "Roboto";
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500;
        margin-top: 5px;
        margin-right: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
    @media screen and (max-width: 400px) {
      .tag-city-name {
        width: 70px;
        .tag {
          height: 25px;
          width: 50px;
        }
      }
    }
  }
  .input-field-width {
    .address {
      width: 73% !important;
    }
  }
  .location-fields {
    display: flex;
    .address {
      width: 100%;
    }
    .form-group,
    .form-group .d-block {
      margin-bottom: 0px;
    }
    ::-webkit-input-placeholder {
      color: #89969f;
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 300;
    }

    :-ms-input-placeholder {
      color: #89969f;
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 300;
    }

    ::-ms-input-placeholder {
      color: #89969f;
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 300;
    }

    ::placeholder {
      font-family: "Roboto";
      font-size: 12px;
      font-weight: 300;
      color: #89969f;
      opacity: 1;
    }

    .submit-button {
      display: inline-block;
      border: none;
      font-size: 12px;
      height: auto !important;
      background: #015376;
      padding: 6px 10px !important;
      margin: 0px 0 0 10px !important;
    }
    button:disabled {
      border: none;
    }
    input {
      height: 30px;
      box-shadow: none;
      width: 100% !important;
      margin: 0px !important;
      font-size: 14px !important;
      padding: 0 10px !important;
      border-bottom: 0px !important;
    }
  }
}
