@import "../../../shared/style/variables";
@import "../../../shared/style/_mixins.scss";

.file-upload {
  border-bottom: 1px solid $grey;
  position: relative;
  z-index: 0;
  .file {
    .position-relative {
      margin-bottom: 0;
    }

    .text-danger {
      width: 100%;
      border-top: 1px solid $primary-red;
      position: absolute;
    }
  }

  label {
    display: block;
    text-transform: uppercase;
  }

  input[type="file"] {
    opacity: 0;
    display: block;
    cursor: pointer;
    margin-top: 0px !important;
  }

  .placeholder {
    position: absolute;
    bottom: 12px;
  }

  .file-details {
    width: 100%;
    display: flex;
    padding: 10px 12px;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    position: absolute;
    bottom: -1px;
    z-index: 100;
    background-color: $light-grey;

    .file-name {
      display: flex;
      align-items: center;
      color: $black;
      font-size: 15px !important;
      font-family: "Roboto" !important;
      font-weight: 400;
      img {
        width: 20px;
      }

      span {
        width: 100px;
        overflow: hidden;
        padding-left: 12px;
        margin-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media (max-width: 320px) {
          width: 80px;
        }
      }
    }

    span {
      cursor: pointer;

      &.remove {
        padding-left: 21px;
        border-left: 1px solid $dark-black;
        color: $tertiary-red;
        font-size: 12px !important;
        font-family: "Roboto" !important;
        font-weight: 500;
        text-transform: uppercase;
      }

      &.disable {
        color: $primary-grey;
        pointer-events: none;
      }
    }
  }

  .placeholder {
    color: $dark-grey;
    font-size: 13px !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    text-transform: capitalize;
  }

  .file-scan {
    position: absolute;
    bottom: -15px;
    display: flex;
    align-items: center;

    img {
      margin-right: 3px;
      width: 10px;
      margin-top: 4px;
    }
    .text-danger {
      margin-top: 1px !important;
    }
  }
}
.allowed-files-error {
  display: block;
  color: red;
  float: right;
  font-size: 12px;
  text-align: right;
  img {
    width: 13px;
    margin-right: 5px;
    margin-top: -4px;
  }
}
.help-text-file-upload {
  margin-top: 20px;
  display: block;
  text-align: right;
  color: #828282;
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: "Roboto" !important;
}
