@import "../../../shared/style/variables";
@import "../../../shared/style/_mixins.scss";

.autocomplete {
  margin-bottom: 0;

  .css-qd55pj .css-1oubc3t .css-1hwfws3 .css-1g6gooi div input {
    border: none !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .css-qd55pj .css-1mr44tx .css-1hwfws3 .css-1g6gooi div input {
    border: none !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .css-1mr44tx {
    .css-5prkxu {
      display: none;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  input.autocomplete-input {
    width: 100%;
    border: 0;
    outline: 0;
    border-bottom: 2px solid #dee4e9;
  }

  input[name="autoComplete"] {
    padding: 0;
    height: 0;
  }

  .css-19qxxs {
    z-index: 1000;
    text-transform: initial;
  }
  .css-10nd86i {
    &:focus {
      outline: none;
    }

    &:active {
      outline: 0 !important;
      border: 0 !important;
    }

    .css-1mr44tx {
      outline-color: #ffffff;
      border: none;
      -webkit-appearance: none;
    }
  }
  .css-1hwfws3 {
    padding: 0px;
  }

  .css-qd55pj {
    @include fontFamily("Roboto", 15px, "normal", 400);
    outline: none;
    div {
      text-transform: none;
      font-size: 15px;
    }
    &:focus {
      outline: none;
    }

    .css-1wy0on6 {
      display: none;
    }
  }
}
