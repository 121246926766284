.application-custom-dropdown-content,
.application-custom-notifiaction-dropdown-content {
  display: flex;
  cursor: pointer;
  &__icon {
    margin-left: 5px;
    margin-top: 2px;
  }
  &__selected-item {
    background: #c3e2ff;
    color: #124a7c !important;
    font-size: 16px !important;
  }
  &__read-item {
    max-width: 100%;
  }

  &__unread-item {
    max-width: 100%;
    @media (max-width: 992px) {
      width: 90%;
    }
  }
  &__notify-circle {
    width: 8px;
    height: 8px;
    border-radius: 17px;
    background-color: #004a79;
    margin-left: 7px;
  }
  &__displayName {
    display: flex;
    align-items: center;
  }
  &__list-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  &__time-ago {
    width: 188px;
    font-size: 12px;
    text-transform: none;
    line-height: 18px;
    color: #000000;
    font-family: "Roboto";
    font-weight: 500;
    margin-left: 50px;
    float: right;
    text-align: right;
    @media (max-width: 992px) {
      margin-left: 0px;
    }
    @media (max-width: 400px) {
      width: 251px;
    }
  }
  &__list-hide {
    display: none;
  }
  &__title {
    font-size: 20px;
    color: #124a7c;
    padding-left: 5px;
    padding-top: 5px;
    text-transform: capitalize;
  }
  &__arrow {
    .down-arrow {
      font-size: 24px;
      padding-top: 13px;
      margin-left: 8px;
    }
  }

  &__list {
    display: block;
    position: relative;
    &__options {
      top: 30px;
      right: 0px;
      width: 180px;
      z-index: 100;
      border-radius: 5px;
      position: absolute;
      background: white;
      text-transform: capitalize;
      box-shadow: 2px 0px 13px rgba(0, 0, 0, 0.49);
    }
    li {
      list-style-type: none;
      padding: 10px;
      color: black;
      cursor: pointer;
      z-index: 100;
      &:hover {
        background: #c3e2ff;
        color: #124a7c;
      }
    }
    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      top: 21px;
      right: 10px;
      z-index: 100;
      border-style: solid;
      border-width: 0 13px 10px 13px;
      border-color: #fff transparent;
    }
  }
}
.ul-width {
  width: fit-content !important;
  width: 500px;
  max-height: 286px;
  overflow-y: scroll;
  @media (max-width: 400px) {
    width: 310px !important;
    display: inline-block;
  }
}
.disable {
  display: none !important;
}
.width-90 {
  width: 90%;
}
.notification-read-color {
  color: #7f7f7f;
}
.overflow-text {
  /* autoprefixer: off */
  -webkit-box-orient: vertical; /* autoprefixer: on */
  max-height: 50px;
  overflow: hidden;
  display: inline-block !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
}
.loderlist {
  width: 300px !important;
}
.notificationLoader {
  text-align: center;
  padding: 115px;
  &__div {
    text-align: center;
  }
  &__img {
    width: 30px !important;
    max-width: none;
  }
}
.blue-circle {
  width: 10px;
}
