@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.description-text {
    padding: 0;
    margin: 0;
    
    pre {
        display: block;
        color: #696969;
        @include fontFamily('Roboto',15px,'normal',400);
        line-height: 25px;
        margin-bottom: 0;
        white-space: pre-wrap;
    }
}