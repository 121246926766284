@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.career-details {
  background-color: #ffffff;
  .referral-bonus-amount {
    span {
      font-size: 15px;
    }
  }
  .career-banner {
    .overlay {
      opacity: 0.9;
    }

    .career-banner-overlay-container {
      display: flex;
      width: 100%;
    }
    .career-banner-overlay-main {
      width: 65%;
      display: flex;
      justify-content: end;
    }

    .career-banner-overlay-socialNetwork-img-main {
      width: 35%;
      height: 35%;
      display: flex;
      justify-content: end;
      
        .career-banner-overrelay-detailsPage {
          background-color: white;
          margin-right: 22px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;

        }
        .career-banner-overrelay-healthPage {
          background-color: #082B5A;
          margin-right: 22px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
        } 
    }
  }
  .container {
    .main-details {
      .details {
        .details-header {
          margin-bottom: 20px;
          padding: 14px 10px 14px 30px;
          background: #d3d9e2;
          text-transform: none;
          border: none;
        }
        .details-body {
          padding: 0px 30px;

          .tabular-view {
            display: flex;
            padding-bottom: 10px !important;
            margin-right: 70px;
            border-bottom: 1px solid #e0dbdb;
            .value {
              width: 50%;
              font-size: 15px;
            }
            .label {
              width: 50%;
              margin-bottom: 0px;
              font-size: 15px;
              text-transform: capitalize;
              color: #101010;
            }
            @media screen and (max-width: 990px) {
              margin-right: 50px;
            }
            @media screen and (max-width: 600px) {
              margin-right: 0px;
            }
          }

          .detail-box {
            .value {
              color: #595959;
              font-weight: 400;
              span {
                .tags {
                  span {
                    background: #fff;
                    border-radius: 16px;
                    min-width: 60px;
                    border: 1px solid #124a7c;
                    text-align: center;
                  }
                }
                .display-pay-rate {
                  color: #595959;
                  font-weight: 400;
                  .unit {
                    font-weight: 400 !important;
                  }
                }
                .description-text {
                  pre {
                    color: #595959;
                  }
                }
                .education-experience-description {
                  .list-of-exp-edu {
                    display: flex;
                    color: #595959;
                    font-weight: 400;
                    align-items: center;
                    padding-bottom: 10px;

                    .list-dot {
                      display: inline-block;
                      width: 7px;
                      height: 7px;
                      border-radius: 20px;
                      border: 1px solid #124a7c;
                      background: #124a7c;
                      margin-right: 15px;
                    }
                  }
                }
              }
            }
          }
        }
        .rounded-header {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          margin-bottom: 40px;

          @media screen and (max-width: 990px) {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            margin-bottom: 40px;
          }
        }
        .closed-rounded-header {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;

          @media screen and (max-width: 990px) {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      max-width: 100%;
      padding: 0;
    }
  }
  .career-banner-closed {
    @include prefix(height, calc(100% - 139px));
    position: absolute;
    right: 0;
    left: 0;
  }
  .contract-details {
    @media (max-width: 992px) {
      display: block;

      & > div {
        max-width: 100%;
        padding: 0;

        .title-value-card {
          border-radius: 0;
        }

        &:first-child {
          .title-value-card {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }
        }

        &:last-child {
          .title-value-card {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
  .career-banner-overlay-component {
    color: $white;
    // text-align: center;
    h2 {
      max-width: 100% !important;
      padding-bottom: 15px;
      color: $white;
      @include fontFamily("Roboto", 30px, normal, 300);
      text-transform: capitalize;

      @media (max-width: 585px) {
        padding-bottom: 20px;
      }
    }

    .description {
      display: flex;
      justify-content: center;

      img {
        width: 20px;
        height: 17px;
      }

      span {
        max-width: 500px;
        padding: 0 0 14px 5px;
        position: relative;
        display: block;
        @include fontFamily("Roboto", 16px, normal, 400);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media (max-width: 585px) {
          max-width: 300px;
        }
      }
    }

    .display-address {
      p {
        padding: 0 0 0px 5px;
        display: inline-block;
        font-size: 16px;
        text-transform: none;
      }
    }

    .form-buttons {
      .cancel-button {
        color: $white;
        border: 1px solid $white;
        text-transform: uppercase;
      }
      .refer-button,
      .apply-button {
        @media (max-width: 400px) {
          margin: 5px;
        }
      }
    }
  }
  .overlay-component {
    text-align: center;

    .position-closed {
      p {
        color: $white;
        @include fontFamily("Roboto", 28px, normal, 300);
        line-height: 35px;
        text-align: center;
        text-transform: none;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      button {
        margin-top: 2%;
        border-radius: 6px;
        background-color: #fff;
        color: #082B5A;
      }
    }

    .form-buttons {
      margin-top: 15px;
    }
  }
  .career-card {
    position: relative;
    top: -75px;
    z-index: 8;
    background: #f4f4f4;
    border-radius: 10px;

    @media (max-width: 768px) {
      top: -25px;
      border-radius: 0;
    }
    @media (max-width: 400px) {
      top: 0px;
    }

    .career-footer-buttons {
      padding-bottom: 50px;
      padding-left: 30px;
      button {
        margin: 0;
        border-radius: 5px;

        &.cancel-button {
          margin-right: 15px;
          background: #fff;
        }
        &.disable {
          color: $primary-grey;
          cursor: not-allowed !important;
        }

        @media (max-width: 585px) {
          width: 100%;

          &.cancel-button {
            border-radius: 6px;
            margin-bottom: 15px;
          }

          &.submit-button {
            border-radius: 6px;
          }
        }
      }

      @media (max-width: 585px) {
        padding-bottom: 30px;
      }

      @media screen and (max-width: 600px) {
        padding: 0 30px;

        .cancel-button {
          margin-right: 0px !important;
        }
      }
    }

    .view-all-positions {
      border-top: 1px solid $grey;
      button {
        padding-left: 30px;
        padding-bottom: 20px;
        margin: 9px 0 0;
        border: 0;
      }
    }
  }
  .banner-exp {
    display: inline-block;
    font-size: 16px;
    .display-pay-rate {
      color: #ffffff;
    }
  }
}

.current-openings-container {
  &__text {
    font-size: 14px;
  }
  .icon-arrow-right {
    color: #124a7c;
    font-size: 16px;
    margin-left: 7px;
  }
}

span.banner-overlay-label {
  color: #ffffff;
  @include fontFamily("Roboto", 16px, normal, 500);
}
