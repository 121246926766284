.user-profile-image {
    img {
        max-width: 90px;
        max-height: 90px;
        width: 90%;
        height: auto;
        padding-bottom: 5px
    }
    .Profile{
        font-size: 80px;
        padding-bottom: 5px
    }
}