@import "../../../shared/style/variables";
@import "../../../shared/style/_mixins.scss";

.input-field {
  margin-bottom: 35px;
  padding-left: 0;
  label {
    font-weight: 500;
    display: block;

    span {
      text-transform: uppercase;
      @include fontFamily("Roboto", 12px, normal, 500);

      &.text-danger {
        @include fontFamily("Roboto", 10px, normal, 500);
        text-transform: none;
      }

      &.required-field {
        color: $primary-red;
      }
      &.validation-message {
        text-transform: lowercase;
        color: $primary-blue;
        margin-left: 10px;
        font-style: italic;
      }
    }
  }

  .horizontal-radio-buttons {
    .form-check {
      margin-right: 52px;

      @media (max-width: 585px) {
        width: 46%;
        padding: 15px 0 0 0;
        margin-right: 10px;
        vertical-align: top;
      }
    }
  }

  .radio-input-textbox {
    width: 125px;
    margin-right: 20px;
    display: inline-block;

    @media (max-width: 585px) {
      width: 49%;
      padding: 0;
      margin: 0;
    }

    input {
      padding-left: 0;
    }

    .text-danger {
      font-size: 10px;
    }
  }

  .label-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pay-rate-range {
    width: 200px;

    @media (max-width: 585px) {
      width: 100%;
      padding-right: 20px;
    }

    .amount-range {
      width: 70px;
      display: inline-block;

      @media (max-width: 585px) {
        width: 45%;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  ::-webkit-input-placeholder {
    color: #89969f;
    font-size: 12px !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    color: #89969f;
    font-size: 12px !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
  }

  ::-moz-placeholder {
    font-size: 12px !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    color: #89969f;
    opacity: 1;
  }

  ::placeholder {
    font-size: 12px !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400;
    color: #89969f;
    opacity: 1;
  }
}
#chatbot input {
  height: 30px;
  line-height: 32px !important;
}

#chatbot input,
#chatbot textarea {
  max-height: 160px;
  overflow: auto;
  border: none;
  border-bottom: 1px solid #bdd6ee;
  resize: none;

  &:focus {
    outline: none;
  }
}
