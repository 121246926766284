#checkbox-container {
  display: flex;
  margin-bottom: 10px;
  input {
    cursor: pointer;
    width: 15px !important;
    margin-top: 0 !important;
  }
  .checkbox-text {
    width: 80%;
    margin: 0 auto;
    line-height: 1.2;
  }
}
