.desktop-body {
    background: #124a7c;
}
.login-calculator-container {
    // max-width: 1330px;
    width: 100%;
    height: 100%;
    background: #124a7c;
    .calculator-layout{
        &__header {
            width: 50%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 40px 10px;
            &__infojini{
                img {
                    width: 50%;
                    background: #ffffff;
                    border-radius: 10px; 
                }
            }
        }

        &__main{
            width: 70%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px 10px 0 0;

            .desktop-component{
                width: 100%;
                margin-bottom: 5%;
                .page-title {
                    width: 100%;
                    padding: 15px;
                    background: #fff;
                    border-radius: 10px 10px 0 0; 

                    .page-heading{
                        margin: 0 auto;
                        font-size: 23px;
                        color: #95ebfb;
                        text-transform: uppercase;
                        font-weight: 600;
                        background: #124a7c;
                        border-radius: 7px 7px 0 0;
                        padding: 3% 6%;
                    }
                }
                .login-saving-calculator{
                    // padding: 15px;
                }

                .form-content{
                    width: 100%;
                }
            }

            
        }

        .app-footer {
            h3{
                color: black;
            }
        }

        .mobile-layout{
            .desktop-footer{
                display: none; 
            }
            .mobile-header{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #124a7c;
                padding: 20px;

                &__infojini{
                    margin: 20px;
                    text-align: center;
                    img {
                        width: 50%;
                        background: #fff;
                        border-radius: 10px; 
                    }
                }
            }

            .calculator-layout__main{
                width: 100%;

                .desktop-component{
                    // margin-bottom: 25%;
                }

                .title {
                    h2{
                        text-align: center;
                    }
                }
                .final-save{
                    margin-bottom: 5%;
                }

                .count{
                    padding: 20px 25px;
                    border-right: none;
                    border-bottom: 1px solid #c5c6c7;  
                }

                .border-no{
                    border-left: none;
                    border-bottom: none;
                    border-top:  1px solid #c5c6c7;  
                }
            }
        }
    }
}