.chatbot-select-options {
  margin-bottom: 10px;
  &__option {
    padding: 7px;
    font-size: 14px;
    min-width: 55px;
    cursor: pointer;
    color: #015376;
    text-align: center;
    border-radius: 20px;
    display: inline-block;
    box-sizing: border-box;
    margin: 5px 5px 5px 3px;
    line-height: 1.3 !important;
    border: 1px solid #015376;
    font-family: "Roboto" !important;
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
  }
  .add-hover {
    &:hover {
      color: #fff !important;
      background: #015376 !important;
    }
  }
  .session-user {
    display: flex;
    justify-content: flex-end;
  }
  .sulross-hover {
    &:hover {
      color: #fff !important;
      background: #b71632 !important;
    }
  }
  .university-hover {
    &:hover {
      color: #000000 !important;
      background: #F6DE94 !important;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .chatbot-select-options {
    margin-bottom: 17px;
  }
}
