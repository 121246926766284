
.contact-add-title {
    margin-left: 24px; 
    margin-right: 28px;
    padding: 34px 26px 0 0;
    border-bottom: 1px solid #12497C;
    h3 {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 19px;
        color: #12497C;
        font-family: "Roboto";
        font-weight: 700;
    }
}

.contact-edit-icon{
    .edit-button {
        margin:20px 15px 20px 24px;
        border-radius: 20px;
        padding: 9px 24px;
        border:2px solid #124a7c;
        font-size: 14px;
    }
}
.cadidate{
    button {
    width: 225px;
}
}

.contact-mobile-distribution-page {
    padding: 15px 0px 0px 0px;
}

.contact-distribution-list {
    span {
        color: #124a7c;
        cursor: pointer;
        font-style: "normal";
        font-weight: 500;
        text-transform: capitalize;
        padding: 0px 0px 10px 0px;
        border-bottom: 3px solid #124a7c;
    }
}
#search-box{
    position:relative;
    border:none;
    border-bottom:1px solid #eee;
    width:40%;
    padding-left:35px;
    min-height: 38px; 
}

input::placeholder {
    color:#89969f;
}
:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color:#89969f !important;
}
::-ms-input-placeholder {
    /* Microsoft Edge */
    color:#89969f;
} 

.search-filter-mobile-input{
    position: relative;
    padding-bottom:15px;
    background: #fff;
    border-bottom: none !important;
}
.search-contact-icon{
    position:absolute;
    top: 26px;
    left: 34px;
    z-index:1;
}

@media (max-width: 992px){
    #search-box{
        width: 100%;
        padding-left: 20px;
        padding-right: 35px;  
    }
    .search-contact-icon{
        top: 10px;
        left: auto;
        right: 15px;
    }
}
