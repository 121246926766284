.public-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 15px;
  align-items: center;
  background: #124a7c;
  justify-content: space-between;

  .vender-logo {
    padding: 5px 10px;
    border-radius: 6px;

    img {
      max-width: 80px;
      max-height: 54px;
    }
  }
}
.public-layout {
  background: #ffffff;
  min-height: 100%;

  .public-layout-body {
    .public-layout-body-header {
      padding-bottom: 70px;
      .public-layout-body-conatiner {
        width: 90%;
        padding: 30px 0px;
        border-radius: 10px;
        margin: 35px auto 0 auto;
        background-color: #ffffff;
        box-shadow: 2px 1px 30px -15px #3d3d3d;
        -moz-box-shadow: 2px 1px 30px -15px #3d3d3d;
        -webkit-box-shadow: 2px 1px 30px -15px #3d3d3d;

        @media screen and (max-width: 600px) {
          width: 100%;
          padding: 0;
          border-radius: 0px;
        }
      }

      .header-back {
        width: 90%;
        margin: 0 auto;
        cursor: pointer;
        padding-top: 40px;
        span {
          font-weight: 500;
          font-size: 16px;
          padding: 10px 20px;
          border-radius: 20px;
          background: #124a7c;
          color: #fff !important;
          text-transform: uppercase;
        }
      }
    }
  }
}
.public-talentpool-category-page {
  min-height: 430px;
  &__title {
    width: 60%;
    margin: 0 auto;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #878787;

    @media screen and (max-width: 770px) {
      width: 100%;
      padding: 20px 15px;
    }
  }

  &__category-container {
    width: 90%;
    margin: 0 auto;
    &__categories {
      padding-top: 50px;
      .category-block {
        display: inline-block;
        margin-bottom: 20px;

        .category-body {
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border: 1px solid rgba(112, 112, 112, 0.18);
          cursor: pointer;
          .category-icon {
            background-repeat: no-repeat;
            height: 60px;
            width: 60px;
            background-size: cover;
          }
          .category-body-title {
            padding-top: 10px;
            font-size: 15px;
            color: #00b8e9;
            text-transform: uppercase;
            font-weight: 500;
          }
        }
        @media screen and (max-width: 600px) {
          padding-right: 0px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      &__categories {
        padding-top: 30px;
        .category-list-conatiner {
          margin: 0 15px;
        }
      }
    }
  }
}
.public-footer {
  padding: 11px 0 9px 0;
  background-color: #fff;
  clear: both;
  color: #878787;
  font-size: 15px;
  text-align: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  @media screen and (max-width: 600px) {
    position: relative;
  }
}
