button {
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: 10px;
  color: #fff;
  flex-basis: 0;
  cursor: pointer;
  box-shadow: none;
  border-radius: 6px;
  line-height: inherit;
  font-family: inherit;
  text-transform: uppercase;

  &.submit {
    background-color: "#124a7c";
    border-radius: 24px;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 500;
  }

  &.position-add-button {
    width: 105px;
    position: relative;
    text-align: right;
    padding-right: 15px;
  }
  &.contact-add-button {
    width: 156px;
    position: relative;
    text-align: right;
    padding-right: 15px;
  }

  &.distribution-add-button {
    width: 190px;
    position: relative;
    text-align: right;
    padding-right: 15px;
  }

  &.default {
    background-color: "#fff";
    color: "#124a7c";
    border: 1px solid "#124a7c";
  }

  &:focus,
  &:hover {
    outline: 0 !important;
    box-shadow: none;
  }

  &:disabled {
    background: "#8b8a9c";
    cursor: not-allowed;
    border: none;
  }
}
