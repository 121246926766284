.user-list-details {
  padding: 0;
  margin: 5px 0;
  border-radius: 10px;
  border: 1px solid #dfdfdf;

  .header-container {
    padding: 20px 25px 0 25px;
    border-bottom: 1px solid #dfdfdf;
    .user-name {
      font-size: 34px;
      font-weight: 600;
      padding-bottom: 5px;
      text-transform: capitalize;
    }
    .user-role {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .experience-field {
      display: flex;
      font-size: 16px;
      margin-right: 20px;
      img {
        margin-right: 10px;
      }
    }
    .available-date {
      display: flex;
      font-size: 16px;
      padding-bottom: 10px;
      .icon-calendar {
        font-size: 18px;
        color: #ababab;
        margin-right: 10px;
      }
      .text {
        padding-right: 5px;
        vertical-align: initial;
      }
    }
    .location-fields {
      .location {
        display: flex;
        margin-right: 20px;
        padding-bottom: 10px;
        img {
          margin-right: 10px;
        }
      }
      .willing-to-relocate {
        img {
          margin-right: 5px;
        }
      }
      .willing-to-relocate,
      .location {
        font-size: 16px;
        align-items: center;
      }
    }
    .veterian-status {
      font-size: 16px;
      padding-bottom: 20px;
      img {
        width: 25px;
        margin-right: 5px;
      }
    }
  }

  .select-talentpool-dropdown {
    position: relative;
    .text {
      font-size: 14px;
    }
    select {
      width: 100%;
      margin-top: 5px;
      font-size: 12px;
      border-radius: 5px;
      background: transparent;
      -webkit-appearance: none;
      text-transform: uppercase;
      border: 1px solid #124a7c;
      padding: 10px 30px 10px 10px;
      &::-ms-expand {
        display: none;
      }
    }
    img {
      width: 12px;
      position: absolute;
      top: 35px;
      right: 10px;
      pointer-events: none;
    }
  }

  &__summary {
    margin-top: 20px;
    .description-text {
      margin: 20px 0;
      pre {
        color: #000000;
      }
    }
  }

  &__key-skills {
    .skill-tags {
      margin-bottom: 10px;
      .title {
        font-size: 18px;
        font-weight: 400;
      }
      .tags span {
        color: #444444;
        font-size: 16px;
        padding: 8px 15px;
        border-radius: 50px;
        margin: 5px 12px 12px 0;
        background-color: #fff;
        border: 1.5px solid #ababab;
      }
    }
  }

  &__summary,
  &__key-skills {
    padding: 0 25px;
  }

  .get-information-form-container {
    padding: 25px 20px;
    border-top: 1px solid #e4e4e4;

    .get-information-form {
      margin-bottom: 0 !important;
      .input-fields {
        .form-group {
          .text-danger {
            font-size: 14px;
          }
          .d-block {
            margin-right: 15px;
            input {
              height: 45px;
              padding: 0 15px;
              border-radius: 8px !important;
              border: 1px solid #c2c2c2 !important;
              &::placeholder {
                color: #000;
              }
            }
            @media screen and (max-width: 768px) {
              margin: 0;
            }
          }
        }
      }
      .send-button {
        margin: 0;
        font-size: 16px;
        width: 100%;
        padding: 13px 10px;
        background-color: #02a0d2;
        border: 1px solid #02a0d2;
      }
    }
  }

  .primary-color {
    color: #000000;
  }

  .color-gray {
    color: #8b8b8b;
  }

  .title {
    font-size: 24px;
    font-weight: 500;
  }

  .success-msg {
    color: #00c85d;
    font-size: 19px;
  }

  .mobile-dropdown {
    display: none;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    border-radius: 0;
    .mobile-dropdown {
      display: block;
      padding: 0 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
      img {
        right: 35px;
      }
    }
    .desktop-dropdown {
      display: none;
    }
    .location-fields,
    .experience-availablity-fields {
      flex-direction: column;
    }
    .header-container {
      border: none;
      padding: 15px 15px 0 15px;
      .available-date {
        padding-top: 10px;
        padding-bottom: 10px;
        .icon-calendar {
          margin-right: 13px;
          margin-left: 6px;
        }
      }
      .location-fields {
        .location {
          padding-bottom: 10px;
          img {
            margin-right: 13px;
            margin-left: 6px;
          }
        }
        .willing-to-relocate {
          padding-bottom: 10px;
        }
      }
      .veterian-status {
        img {
          margin-right: 8px;
        }
      }
      .experience-field img {
        margin-right: 12px;
        margin-left: 4px;
      }
    }
    &__summary,
    &__key-skills {
      padding: 0 15px;
    }
    .get-information-form {
      padding: 25px 15px 25px 15px;
    }
  }
}
.font-size-16 {
  font-size: 16px;
}
