@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';


.career-footer {
    padding: 5px 0px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    p {
        // color: $white;
        display: inline-block;
        margin-left: 10px;
        line-height: 25px;
        @include fontFamily('Roboto', 12px, normal, 400);

        span {
            font-weight: 500;
        }
        @media screen and (max-width: 768px) {
            margin-left: 0px;
        }
    }
    a {
        // color: $white;
        display: inline-block;
        margin-left: 3px;
        line-height: 25px;
        color: black;
        text-decoration: underline !important;
        @include fontFamily('Roboto', 12px, normal, 400);

        span {
            font-weight: 500;
        }
        @media screen and (max-width: 768px) {
            margin-left: 0px;
        }
    }
    .desktop-footer {
        display: block;
        h3{
            display: inline-block;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobile-footer {
        display: none;

        @media (max-width: 768px) {
            display: block;
        }
    }
}