@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.applicant-detail-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px !important;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 440px) {
    display: block;
    padding-top: 0 !important;
  }

  .applicant-title {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .application-job-title-container {
    display: flex;
    flex-direction: column;

    .AddmarginBottom {
        margin-bottom: 20px;
    }

    .application-label {
      margin-right: 10px;
      font-weight: 500;
    }
  }

  h2 {
    color: $black;
    @include fontFamily("Roboto", 24px, "normal", 500);
    text-transform: capitalize;

    @media (max-width: 768px) {
      margin-top: 25px;
    }
  }

  .display-date {
    margin-left: 40px;
    color: $dark-grey;
    @include fontFamily("Roboto", 12px, "normal", 400);

    @media (max-width: 768px) {
      margin: 10px 0 0 18px;
    }
  }
}

.applicant-job-title {
  padding-top: 8px;

  p {
    color: $dark-grey;
    @include fontFamily("Roboto", 12px, "normal", 400);
    text-transform: capitalize;

    span {
      font-weight: 500;
    }
  }
}

.application-details {
  margin-bottom: 50px;

  .details-page {
    .application-assessment-list {
      border-top: 1px solid #083761;
      padding: 30px 42px 50px 42px;
      @media screen and (max-width: 600px) {
        padding: 30px 15px 50px 15px;
        margin-bottom: 40px;
      }
      .action-buttons {
        padding-bottom: 20px;
        .send-button {
          padding: 12px 40px;
          margin-top: 20px;
        }
      }

      .mobile-assessment-note {
        padding-top: 20px;
        .add-mobile-assessment-note {
          color: #124a7c;
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
        }
      }

      .default-assessments-list {
        display: flex;
        .previously-sent-assessments {
          padding-right: 60px;
          padding-bottom: 15px;
        }
        .previously-sent-list .skill,
        .skills-list {
          font-size: 14px;
          line-height: 20px;
          color: #414141;
          font-weight: 500;
          padding-bottom: 15px;
        }
        .default-assessments-header {
          text-transform: uppercase;
          font-size: 14px;
          line-height: 20px;
          color: #414141;
          font-weight: 400;
        }
        .skill {
          margin-right: 10px;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          .previously-sent-assessments {
            padding-bottom: 10px;
            padding-right: 0;
          }
        }
        @media screen and (max-width: 500px) {
          .default-assessments-header {
            font-size: 12px;
          }
          .previously-sent-list .skill {
            font-size: 13px;
          }
        }
      }

      .add-assessment {
        padding-bottom: 15px;
        .heading {
          font-size: 22px;
          line-height: 35px;
          color: #124a7c;
          font-weight: 400;
          padding-bottom: 5px;
          text-transform: uppercase;
        }
        .action-buttons {
          padding-bottom: 10px;
          .edit-button,
          .submit-button {
            margin: 0;
            border-radius: 0;
          }
          .btn-edit-active {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }
          .btn-submit-active {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
          }
          .submit-button {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
          }
          .edit-button {
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
          }
        }
        @media screen and (max-width: 500px) {
          .heading {
            font-size: 18px;
          }
          .action-buttons {
            .submit-button,
            .edit-button {
              max-width: 200px;
              padding: 12px 10px;
              font-size: 12px;
            }
          }
        }
        @media screen and (max-width: 415px) {
          .action-buttons {
            .submit-button,
            .edit-button {
              max-width: 165px;
              padding: 10px;
              font-size: 10px;
            }
          }
        }
        @media screen and (max-width: 350px) {
          .heading {
            font-size: 16px;
          }
          .action-buttons {
            .submit-button,
            .edit-button {
              padding: 9px;
              font-size: 9px;
            }
          }
        }
      }

      .assessment-list-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .assessment-on-call-note {
          width: 45%;
        }
        .assessment-search-list {
          display: flex;
          flex-direction: column;
        }
        .assessment-scrollable-list {
          width: 51%;
          @media screen and (max-width: 992px) {
            width: 100%;
          }
        }
        .assessment-email-list-checkboxes {
          max-height: 340px;
          height: auto;
          overflow: auto;
          .form-check {
            padding: 5px 0;
          }
          .d-block {
            cursor: pointer;
          }
        }
      }

      .success-msg {
        margin: 40px 0 15px 0;
        padding: 20px 15px;
        border-radius: 10px;
        color: #318502;
        font-size: 14px;
        border: 1px solid #318502;
      }

      .assessment-on-call-note {
        .add-assessment-note {
          text-transform: uppercase;
          font-size: 14px;
          color: #124a7c;
          font-weight: 600;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-between;
          .edit-icon {
            cursor: pointer;
          }
        }
        .assessment-note {
          margin-bottom: 90px;
          .assessment-list .d-block .form-control {
            min-height: 250px;
            max-height: 250px;
            padding: 15px;
            font-size: 15px;
            text-align: justify;
            border: 1px solid #ced4da !important;
          }
        }
        .assessment-btn {
          float: right;
          margin-right: 0;
          padding: 12px 35px;
        }
        .submitted-assessment-note {
          word-break: break-all;
          line-height: 25px;
          max-height: 300px;
          overflow-y: scroll;
          padding-right: 20px;
          font-size: 15px;
          white-space: pre-line;
        }
      }
    }
    .page-title {
      @media (max-width: 768px) {
        border: 0;
      }
    }

    .content-title-header {
      @media (max-width: 768px) {
        border: 0;
      }

      .content-title {
        h3 {
          @media (max-width: 768px) {
            padding-bottom: 20px;
            border: 0;
            display: block;
          }
        }

        .sub-header-text {
          .sub-header {
            margin: 0 0 8px 0px;
            color: #666;

            .show-clock {
              &:before {
                @media (max-width: 768px) {
                  background: url("../../../../../shared/images/clock.svg")
                    no-repeat;
                  width: 15px;
                  margin: 0 11px 0 0;
                }
              }
            }
          }
        }

        .drop-down {
          margin-top: 20px;
          width: 215px;
        }

        &.new {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $orange;
              background: $orange;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }

        //new added items in list
        &.underReview {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $orange;
              background: $orange;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }
        &.acceptOffer {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $orange;
              background: $orange;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }
        &.preemploymentCheck {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $orange;
              background: $orange;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }

        &.shortlisted {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $green;
              background: $green;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }

        &.interviewed {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $primary-purple;
              background: $primary-purple;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }

        &.madeAnOffer {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $primary-blue;
              background: $primary-blue;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }

        &.onBoard {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $dark-blue;
              background: $dark-blue;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }

        &.rejected {
          .drop-down {
            .css-1whs5fm,
            .css-1ohxolz {
              border: 1px solid $secondary-red;
              background: $secondary-red;
            }
            .css-xp4uvy {
              color: $white;
            }

            &:after {
              position: absolute;
              top: 10%;
              right: 15px;
            }
          }
        }
      }
    }

    @media (max-width: 440px) {
      padding-bottom: 20px;
    }
  }

  .details-header {
    margin-bottom: 26px !important;
  }

  .details-body,
  .details-footer {
    padding: 0 42px;

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }
}

.loading-assessment {
  font-size: 26px;
  color: #083761;
  padding: 30px 42px 50px 42px;
  border-top: 1px solid #083761;

  @media screen and (max-width: 600px) {
    font-size: 22px;
    text-align: center;
    padding: 30px 15px 50px 15px;
  }
}
