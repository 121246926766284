@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.social-share-page {
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);

    .warning-message {
        color: #fff;
        @include fontFamily('Roboto',18px,'normal',500);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}