@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.app-header {
    min-height: 67px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    background-color: $blue;
    @include prefix(box-shadow, 0px 0px 7.81px 3.19px rgba(0, 0, 0, 0.4));

    .app-header-logo {
        display: flex;
        align-items: center;

        h1 {
            width: 176px;
            display: flex;
            align-items: center;
            position: relative;
    
            img {
                padding-bottom: 5px;
                max-width: 134px;
                display: inline-block;
            }

            &:after {
                content: '';
                width: 1px;
                height: 45px;
                background: rgba(255, 255, 255, 0.25);
                position: absolute;
                right: 0;
                top: 8px;
            }
        }
    
        span.selected-menu {
            padding-left: 30px;
            display: inline-block;
            color: $black;
            @include fontFamily('Roboto', 16px, normal, 400);
            text-transform: capitalize;
        }

        nav {
            ul {
                display: flex;
                li {
                    cursor: pointer;
                    position: relative;
                    a {
                        padding: 15px 10px;
                        color: $white;
                        @include fontFamily('Roboto', 18px, normal, 400);

                        @media screen and (max-width:1096px) {
                            padding: 11px 7px;
                            font-size: 16px;
                        }
                    }

                    &.active {
                        a {
                            background: $dark-blue;
                            position: relative;
                            
                            &:before {
                                content: '';
                                background: #fff;
                                width: 80%;
                                height: 3px;
                                position: absolute;
                                bottom: 6px;
                                left: 10%;

                                // @media screen and (max-width:1096px) {
                                //     bottom: 6px;
                                //     left: 10px;
                                // }
                            }
                        }
                    }

                    &:first-child {
                        padding-left: 58px;

                        @media screen and (max-width:1200px) {
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
    }

    .vendor-info {
        display: flex;
        align-items: center;

        .user-info {
            .user-name-dropdown {
                .dropdown {
                    button {
                        background: transparent;
                        border: none;

                        .user-profile-image {
                            max-height: 40px;
                            img {
                                max-width: 40px;
                            }
                        }

                        &:after {
                            display: none !important;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }

                   .dropdown-menu.show {
                        left: -60px !important;
                    }
                }
            }
        }

        .vendor-logo {
            padding: 6px;
            width: 180px;
            padding-left: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: $white;
            border-radius: 15px;

            img {
                max-width: 80px;
                max-height: 54px;
            }
            @media screen and (max-width:1024px) {
                width: 150px;
            }
        }
    }
}