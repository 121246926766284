@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.app-header {
  &.career-header {
    background: $white;
    .app-header-logo {
      span {
        &.selected-menu {
          color: $green;
          @include fontFamily("Roboto", 18px, normal, 400);
        }
      }
      h1 {
        width: initial;
        padding-right: 20px;
        &.vendor {
          margin-right: 10px;
          border-right: 1px solid #eee;
        }
        img {
          max-width: 116px;
        }
        &::after {
          height: 10px;
        }
      }
      h2 {
        color: $primary-blue;
        @include fontFamily("Roboto", 28px, normal, 400);
      }
    }

    .vendor-logo {
      display: flex;
      .app-header-logo {
        margin-left: 20px;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
.hide-block {
  display: none;
}

.candidate-info {
  display: flex;
  align-items: center;
  .user-icon {
    font-size: 36px;
  }
  &__dropdown {
    display: flex;
    margin-left: 15px;
    align-items: center;
    .icon-user-circle {
      font-size: 32px;
    }
  }
  &__notifications {
    position: relative;
    margin: 0 5px;
    &__count {
      width: 15px;
      height: 15px;
      padding-top: 1px;
      position: absolute;
      text-align: center;
      top: 5px;
      right: -3px;
      color: #fff;
      font-weight: 600;
      font-size: 11px;
      background: red;
      border-radius: 10px;
    }
    .bell-icon {
      font-size: 26px !important;
    }
  }

  .middle-line {
    width: 1px;
    height: 45px;
    margin: 0 10px 0 10px;
    background: lightgray;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.candidate-header-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  .login {
    margin-left: 20px;
    button {
      padding: 10px 30px;
      border-radius: 6px;
      border: 1px solid #124a7c;
    }
  }
  .signup {
    margin-left: 20px;
    padding-right: 20px;
    border-right: 1px solid lightgray;
    button {
      background: #fff;
      color: #124a7c;
      border: 1px solid #124a7c;
      border-radius: 6px;
    }
  }
}
.application-custom-notifiaction-dropdown-content {
  &__list {
    &__options {
      top: 26px;
      right: -6px;
    }
    &:before {
      top: 16px;
      right: 1px;
    }
  }
  &__title {
    padding-top: 0px;
  }
  @media (max-width: 992px) {
    &__list {
      &__options {
        top: 31px;
        left: -45px;
      }
      &:before {
        top: 21px;
        right: 7px;
      }
    }
  }
}
