@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?es76xz");
  src: url("./icomoon.eot?es76xz#iefix") format("embedded-opentype"),
    url("./icomoon.ttf?es76xz") format("truetype"),
    url("./icomoon.woff?es76xz") format("woff"),
    url("./icomoon.svg?es76xz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-link:before {
  content: "\e900";
}
.icon-content_copy:before {
  content: "\e901";
}
.icon-list:before {
  content: "\e902";
}
.icon-location-arrow:before {
  content: "\e903";
}
.icon-briefcase:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-sort-asc:before {
  content: "\e906";
}
.icon-bag:before {
  content: "\e907";
}
.icon-direction:before {
  content: "\e908";
}
.icon-calendar:before {
  content: "\e909";
}
.icon-location1:before {
  content: "\e90a";
}
.icon-bell:before {
  content: "\e90b";
}
.icon-user-circle:before {
  content: "\e90c";
}
.icon-sort-desc:before {
  content: "\e90d";
}
.icon-check_box_outline_blank:before {
  content: "\e90e";
}
.icon-check_box:before {
  content: "\e90f";
}
.icon-radio_button_checked:before {
  content: "\e910";
}
.icon-panorama_fish_eye:before {
  content: "\e911";
}
.icon-circle-o:before {
  content: "\e912";
}
.icon-check-circle:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e914";
}
.icon-minus:before {
  content: "\e915";
}
.icon-minimize:before {
  content: "\e916";
}
.icon-clear:before {
  content: "\e917";
}
.icon-circle-right:before {
  content: "\e918";
}
.icon-circle-left:before {
  content: "\e919";
}
.icon-chevron-with-circle-right:before {
  content: "\e91a";
}
.icon-chevron-with-circle-left:before {
  content: "\e91b";
}
.icon-cheveron-outline-right:before {
  content: "\e91c";
}
.icon-cheveron-outline-left:before {
  content: "\e91d";
}
.icon-star-full:before {
  content: "\e91e";
}
.icon-star-empty:before {
  content: "\e91f";
}
.icon-pen:before {
  content: "\e920";
}
.icon-location2:before {
  content: "\e921";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-redo:before {
  content: "\e968";
}
.icon-download:before {
  content: "\e9c7";
}
.icon-link1:before {
  content: "\e9cb";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-arrow-left:before {
  content: "\ea40";
}
.icon-file-pdf:before {
  content: "\eadf";
}
