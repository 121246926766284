@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.career-add {
    padding: 0;

    .page-title {
        h2 {
            padding: 22px 0 0 0;
            color: $black;
            @include fontFamily('Roboto', 18px, normal, 400); 
            text-align: center;
        }
    }

    .add-page {
        max-width: 1340px;
        margin: 35px auto;
        background: $white;
        border-radius: 10px;
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.08);
        position: relative;
        top: -85px;

        .notice-text {
            padding: 40px 0;
            display: block;
            color: $primary-grey;
            @include fontFamily('Roboto', 14px, normal, 400); 
            text-align: center;

            span {
                color: $primary-red;
            }

            @media(max-width: 440px) {
                padding: 40px 20px;
            }
        }

        @media (max-width: 768px) {
            border-radius: 0;
        }
    }

    .career-form {
        .career-form-heading {
            padding: 15px 0 59px 0;

            h3 {
                color: $black;
                @include fontFamily('Roboto', 24px, normal, 500); 
                text-align: center;
                text-transform: capitalize;

                @media(max-width: 768px) {
                    max-width: 500px;
                    margin: 0 auto;
                    word-break: break-word;
                }
            }

            .display-address {
                padding-top: 18px;
                justify-content: center;
            }
        }

        .referal-form {
            .referal-title {
                max-width: 650px;
                padding: 0;
                margin: 0 auto;

                h2 {
                    padding: 14px 0;
                    display: inline-block;
                    color: $blue;
                    @include fontFamily('Roboto', 18px, normal, 700); 
                    text-transform: capitalize;

                    @media(max-width: 768px) {
                        padding-left: 15px; 
                    }
                }
            }

            .form-fields {
                max-width: 650px;
                margin: 0 auto;
            }
        }

        .form-buttons {
            padding: 0 15px 30px;
            margin: 0 auto 120px;
            text-align: center;

            button {
                max-width: 600px;
                width: 100%;
                margin: 0;
                border-radius: 10px;
            }
        }

        .text-danger {
            color: #dc3545 !important;
        }
    }

    .overlay-component {
        .unsubscribe-overlay-component {
            text-align: center;

            h1 {
                padding-bottom: 20px;
                text-transform: initial;
                @include fontFamily('Roboto', 36px, normal, 400);

                @media (max-width: 440px) {
                    padding-bottom: 15px;
                    @include fontFamily('Roboto', 30px, normal, 400);
                }
            }

            h2 {
                display: inline-block;
                position: relative;
                color: $white;
                @include fontFamily('Roboto', 24px, normal, 400);

                &:before {
                    
                    content: '';
                    width: 80%;
                    height: 3px;
                    background: $white;
                    position: absolute;
                    top: -44%;
                    left: 13%
                    
                }

                @media (max-width: 440px) {
                    @include fontFamily('Roboto', 20px, normal, 400);

                    &:before {
                        
                        content: '';
                        width: 80%;
                        height: 3px;
                        background: $white;
                        position: absolute;
                        top: -44%;
                        left: 13%;
                        
                    }
                }
            }

            .description {
                margin-top: 20px;
                margin-bottom: 5px;

                span,p {
                    @include fontFamily('Roboto', 16px, normal, 500);
                }

                span.banner-overlay-label {
                    margin-right: 5px;
                    display: inline-block;
                }
            }

            .display-address {
                p {
                    margin-left: 5px;
                }
            }
        }
    }
}
.my-profile-page{
    padding-bottom: 30px;
    .main-details{
        .details{
            margin-bottom: 20px;
            .more-detail-header{
                text-transform: none;
            }
        
        .details-header {
            margin-bottom: 30px;
        }
        .details-body {
            .detail-box{
                .value {
                    color: #212529;
                    font-weight: 500;
                }
                .text-capitalize {
                    text-transform: capitalize;
                }
            }
        }
        }
    }
    .my-profile{
        padding: 30px 0 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .my-profile-title{
            h2{
                color: #124a7c;
                font-size: 30px;
            }
        }
        .submit {
        padding: 7px 20px;
        }
    }

.resume-section {
    .resume-title{
        margin-bottom: 8px;
        span{
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500; 
        }
    }
    .resume-download{
        padding-bottom: 20px !important;
    }
    }
}



