.chatbot-job-apply-from {
  border-radius: 10px;
  .invalid-input {
    border-bottom: none !important;
  }
  &__heading-refer {
    .refer-msg {
      padding: 10px 40px 0px 15px;
      span {
        font-size: 14px;
        color: #4f4f4f;
        font-family: "Roboto";
        font-weight: 400;
      }
    }
    .title-border {
      border-bottom: 1px solid #c0c0c0;
    }
    .form-title {
      padding: 10px 15px;
      font-weight: 500;
      color: #015376;
      text-align: center;
      margin: 0;
      font-size: 20px !important;
      font-family: "Roboto" !important;
    }
  }
  &__heading {
    padding: 15px 15px 5px;
    font-weight: 500;
    color: #015376;
    font-size: 20px !important;
    font-family: "Roboto" !important;
    text-transform: capitalize;
    h6 {
      margin: 0;
      font-size: 20px !important;
      font-family: "Roboto" !important;
    }
  }
  .error-field {
    border: 1px solid red !important;
  }
  .resume-error,
  .resume-fieldset {
    height: 38px;
  }

  .form-fields .allowed-files-error {
    margin-top: 9px !important;
  }

  .fieldset {
    display: flex;
    height: 40px;
    flex-direction: column;
    border: 1px solid #707070;
    border-radius: 6px;
    position: relative;
    padding-bottom: 0 !important;
    margin-bottom: 30px;
    width: 100%;
    background: #fff;
    border-radius: 6px;

    .label {
      position: absolute;
      top: -8px;
      z-index: 1;
      left: 20px;
      padding: 0 5px;
      line-height: 1;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #707070;
      background: white;

      span {
        color: red;
      }
    }
    .form-group {
      margin-bottom: 0;
      
    }
    .form-control {
      border-bottom: none !important;
      line-height: normal !important;
    }
    .file-upload {
      .file-details {
        background: #fff;
        padding: 0px 12px !important;
      }
      .file-scan {
        bottom: -27px !important;
        img {
          margin-top: 5px;
        }
      }
    }
  }

  .skills-fieldset {
    height: 40px !important;
  }

  &__details {
    padding: 15px 15px 10px;
    form {
      padding: 0px;
    }
    .form-fields {
      .location-search-input {
        border: none !important;
        line-height: normal !important;
      }
      span.text-danger.d-block {
        border-top: 0;
        position: absolute;
        top: 27px;
        left: 0;
        padding: 0;
        font-size: 10px;
        margin-top: 8px;
      }
      .form-control:focus {
        box-shadow: none;
      }
      .location-icon {
        display: none;
      }
      .autocomplete {
        .css-qd55pj div {
          font-size: 13px;
        }
        #Skills {
          margin: 5px 10px 3.5px 0px;
          padding-left: 15px;
          .css-1mr44tx {
            min-height: 30px !important;
          }
        }

        .css-1pidwmw {
          width: 50px;
          overflow: hidden;
          white-space: nowrap;
          padding: 7px 0 7px 7px;
          text-overflow: ellipsis;
        }
        .css-20bziz {
          padding: 4px 5px 0 0;
        }
        .css-1hwfws3 {
          height: 28px;
          margin-top: 1px;
        }

        .css-1oubc3t {
          min-height: 30px;
          .css-1hwfws3 {
            height: 28px;
            margin-top: 1px;
            align-content: center;
            .css-5prkxu {
              display: none !important;
              // padding: 0;
              // padding-left: 10px;
            }
          }
        }

        .css-1mr44tx:hover,
        .css-trpvs:hover,
        .css-1hwfws3:focus,
        .css-1hwfws3:hover {
          border: none !important;
          box-shadow: none !important;
        }

        .css-1hwfws3 {
          .css-2hh98u {
            width: 65px;
            @media screen and (max-width: 500px) {
              width: 70px;
            }
            @media screen and (max-width: 400px) {
              width: 60px;
            }
          }
        }
        #autoComplete {
          margin-top: 0px;
          display: none;
        }
        .css-19qxxs {
          margin: 2px 0 0 -15px;
        }
        .css-1tnz7qj {
          padding: 5px 5px 3px;
          width: 65px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media screen and (max-width: 500) {
            width: 50;
          }
          @media screen and (max-width: 400px) {
            width: 40px;
          }
        }
      }
      input {
        height: 25px !important;
        margin-top: 7px;
        padding: 0 25px;
        font-size: 14px !important;
      }

      input #file {
        margin-top: 1px !important;
      }

      input::-ms-clear {
        display: none;
      }
      .placeholder {
        padding-top: 3px;
        padding-left: 25px;
        line-height: 10px;
        font-size: 12px;
      }
      .file-upload {
        top: 5px;
        border-bottom: none;
      }
      .file-upload .file .text-danger {
        border-top: none;
        padding: 0px !important;
        margin-top: 0px !important;
        bottom: -27px;
      }

      ::-webkit-input-placeholder {
        color: #89969f;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 300;
      }

      :-ms-input-placeholder {
        color: #89969f;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 300;
      }

      ::-ms-input-placeholder {
        color: #89969f;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 300;
      }

      ::placeholder {
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 300;
        color: #89969f;
        opacity: 1;
      }
    }
  }
  .submit-btn {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    text-align: center;
    text-transform: capitalize;
    background-color: #015376;
  }

  .sub-heading {
    display: block;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-size: 14px !important;
    color: #212529 !important;
    font-weight: 500 !important;
  }
  .experience-div {
    display: flex;
    padding-top: 2px;
    flex-direction: row;
    .exp-field,
    .month-field {
      position: relative;
    }
  }
  .info-msg {
    font-size: 10px;
    color: #89969f;
    font-weight: 700;
    position: absolute;
    top: 40px;
    padding-left: 24px;
  }
  .error-msg {
    color: red;
  }
  .address {
    .form-group .text-danger {
      margin-top: 15px !important;
    }
  }
}
