@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

// Desktop layout styling
.app-layout {
    height: 100%;
    
    .app-desktop-layout {
        height: 100%;
        background: #eeeeee;
    
        .main {
            @include prefix(height, calc(100% - 100px));
            // display: flex;
        
            .app-sidebar {
                width: 183px;
                height: 100%;
            }
        
            .app-component {
                width: 100%;
                height: 100%;
                overflow: auto;
                background: #eeeeee;
                // background: url('../../images/list-bg.png') center/cover;

                &.career-app-component {
                    width: 100%;
                    overflow: initial;
                }
            }
        }
    
        @media (max-width:992px) {
            display: none;
        }
    }
    
    // Mobile layout styling
    .app-mobile-layout {
        height: 100%;

        .mobile-footer {
            .app-footer {
                // top:90%;
                // background-color: white;
                position: relative;
            }
        }
        .border-marging{
            .app-footer {
                bottom: 35px;
                position: relative;
            }
        }

        .main {
            min-height: 91vh;
            // @include prefix(height, calc(100% - 110px));
            background: #eeeeee;

            .app-mobile-menubar {
                width: 0;
                background-color: rgba(0,0,0,.5);
                position: fixed;
                z-index: 30;
                top: 0;
                bottom: 0;
                overflow: hidden;

                &.show-menubar {
                    @include animation('slide-right 0.2s forwards');   
                }

                &.hide-menubar {
                    @include animation('slide-left 0.2s forwards');   
                }
            }

            .dashboard {
                background: #eeeeee;

                @media (max-width: 440px) {
                    padding: 0 0 50px 0;
                }
            }

            .app-sidebar {
                width: 35%;
            }

            @media screen and (min-width: 760px) and (max-width: 768px) {
                min-height: 89vh;
            }
            @media screen and (max-width: 600px) {
                min-height: 80vh;
            }
        }

        @media (min-width:992px) {
            display: none;
        }

        @media (max-width:993px) {
            display: block;
        }
    }
}
.desktop-component{
    height: 94%;
    @media (max-width: 768px) {
        height: 94%
    }
}
.user-profile {
    display: flex;
    align-items: center;
}

@include keyframes('slide-right') {
    0% { width: 0%; }
    100% { width: 100%; }
}

@include keyframes('slide-left') {
    0% { width: 100%; }
    100% { width: 0%; }
}

.app-breadcrumb {
    @media (max-width: 768px) {
        display: none;
    }
}