.chatbot-text-container {
  display: flex;
  align-items: flex-end;
  word-break: break-word;

  .chatbot-icon {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .chatbot-text-image {
    height: 30px;
    max-width: 75px;
  }

  .number {
    color: #015376;
    padding-left: 5px;
    text-decoration: underline !important;
  }

  .chatbot-text {
    color: #222;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    background-color: #e4e6ec;
    padding: 10px 15px 10px 10px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &__multiple{
      margin-bottom: 10px;
    }
  }
}

.margin-left {
  margin-left: 45px;
}

.chatbot-name {
  font-size: 14px;
  color: #4f4f4f;
  margin-bottom: 7px;
}

.chatbot-text-right {
  justify-content: flex-end;
  .chatbot-text {
    color: #fff;
    background-color: #015376;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
  }
}

// ****************** Chatbot Link css ******************//
.chatbot-link {
  color: #015376;
  font-weight: 500;
  padding-right: 5px;
  vertical-align: initial;
  text-decoration: underline !important;
}
.chatbot-link:hover {
  color: #015376;
}
