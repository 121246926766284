.empty-search-results {
    padding-top: 60px;
    margin-bottom: 50px;
    .no-results-icon {
        color: #124a7c;
        font-size: 100px;
    }
    .no-results-text {
        border-bottom: 3px solid #dddddd;
        width: 500px;
        margin: 0 auto;
        padding: 20px 0 10px 0;
        .notifications-text {
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 22px;
            color: #333333;
            font-weight: 500;
            padding-bottom: 10px;
        }
        .text {
            color: #124a7c;
            font-weight: 700;
            font-size: 20px;
            letter-spacing: 1px;
            line-height: 22px;
        }
        .back-button {
            padding: 10px 40px;
            margin: 10px 0;
        }
    }
    .join-talentdome {
        margin: 0 10px;
        color: #124a7c;
        background: #fff;
        padding: 10px 40px;
        border:2px solid #124a7c;
    }
    .refer-to-talentdome {
        padding: 11px 30px;
    }
    .join-talentdome , .refer-to-talentdome{
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
    }
}
@media screen and (max-width: 600px) {
    .empty-search-results {
        .no-results-text {
            width: 300px;
        }
        .refer-to-talentdome {
            margin-bottom: 10px;
        }
    }
}