@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.experience-input {
    h3 {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        color: $dark-grey;
        @include fontFamily('Roboto', 12px, normal, 400);
        text-transform: uppercase;
    }

    .years-months-input {
        display: flex;

        .years-experience {
            width: 100%;
            display: flex !important;
            align-items: center;

            label {
                color: $black;
                @include fontFamily('Roboto', 12px, normal, 500);
            }

            input {
                width: 100%;
                max-width: 100px;
                height: 25px;
                padding: 0;
                margin: 0 12px;
                border: 0;
                border-bottom: 1px solid $grey;
                color: $black;
                @include fontFamily('Roboto', 12px, normal, 500);

                &:-ms-input-placeholder { 
                    color: $dark-grey;
                    @include fontFamily('Roboto', 12px, normal, 400);
                }
        
                &::-ms-input-placeholder { 
                    color: $dark-grey;
                    @include fontFamily('Roboto', 12px, normal, 400);
                }

                &::placeholder {
                    color: $dark-grey;
                    @include fontFamily('Roboto', 12px, normal, 400);
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

.desktop-filter-accordian .desktop-filter-accordian-body-container .desktop-filter-accordian-body{
    text-transform: none;

    .checkboxes .form-check .d-block input[type=checkbox] ~ span{
        text-transform: none;
    }
}

span {
    &.experience {
        padding-left: 5px;
        @include fontFamily('Roboto', 12px, normal, 500);
    }
}

.desktop-search {
    margin-top: 30px;
}

.talentpool-desktop-list {
    width: 80%;
    .tabular-view {
        ul.list-title {
            li:first-child {
                width: 35%;
            }

            li:nth-child(2) {
                width: 50%;
            }
        }

        ul.list-items {
            li.item {
                ul.item-row {
                    li:first-child {
                        width: 35%;
                    }

                    li:nth-child(2) {
                        width: 50%;
                        max-width: 500px;
                        white-space: nowrap;  
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
.filters{
    .selected-filter{
        padding-top: 0px !important;

        .vertical-radio-buttons{
            padding-top: 15px;
        }
        
        .checkboxes .form-check .d-block input[type=checkbox] ~ span{
            text-transform: none;
        }
    }
}