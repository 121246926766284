@import "App/shared/style/_variables.scss";
@import "App/shared/style/_mixins.scss";

.app-header {
  &.career-header {
    background: $white;

    .app-header-logo {
      span {
        &.selected-menu {
          color: $green;
          @include fontFamily("Roboto", 18px, normal, 400);
        }
      }

      h1 {
        width: initial;
        padding-right: 20px;

        &.vendor {
          margin-right: 10px;
          border-right: 1px solid #eee;
        }

        img {
          max-width: 116px;
        }
        &::after {
          height: 10px;
        }
      }

      h2 {
        color: #124a7c;
        @include fontFamily("Roboto", 28px, normal, 400);
      }
    }

    .vendor-logo {
      display: flex;

      .app-header-logo {
        margin-left: 20px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .candidate-info {
    display: flex;
    align-items: center;

    &__dropdown {
      display: flex;
      margin-left: 15px;
    }
    &__notifications {
      position: relative;
      margin: 0 5px;
      &__count {
        width: 15px;
        height: 15px;
        padding-top: 1px;
        position: absolute;
        text-align: center;
        top: 5px;
        right: -3px;
        color: #fff;
        font-weight: 600;
        font-size: 11px;
        background: red;
        border-radius: 10px;
      }
      .bell-icon {
        font-size: 26px !important;
      }
    }

    .middle-line {
      width: 1px;
      height: 45px;
      margin: 0 10px 0 10px;
      background: lightgray;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
}
.hide-block {
  display: none;
}
