@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.mobile-menubar {
    width: 80%;
    height: 100%;
    background-color: $primary-blue;
    box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.22);
    overflow: auto;
    
    ul.mobile-menubar-items {
        padding-bottom: 30px;
        li {
            // border-bottom: 2px solid rgba(137, 150, 159,0.2);
            padding: 18px 20px;
            color: white;
            font-size: 18px;

            &.user-profile-mobile-menubar {
                padding: 20px 14px;
                background: $white;
                @include fontFamily('Roboto', 16px, normal, 400);
                text-transform: capitalize;

                .user-profile {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;

                    .user-profile-image {
                        margin-right: 5px;
                    }

                    .user-profile-name {
                        span {
                            color: $blue;
                            @include fontFamily('Roboto', 20px, normal, 400);
                        }
                    }
                }
            }

            &.active-mobile-menu {
                position: relative;
                background-color: $blue;

                // &::before {
                //     content: '';
                //     background: url('../../images/active-menu.png') no-repeat center/cover;
                //     height: 47px;
                //     width: 10px;
                //     position: absolute;
                //     right: 0;
                // }

                // a {
                    span {
                        padding-bottom: 10px;
                        display: inline-block;
                        border-bottom: 3px solid $white;
                    }
                // }
            }

            a {
                padding: 25px 15px;
                color: $white;
                display: block;
                @include fontFamily('Roboto', 14px, normal, 500);
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}