.chatbot-job-details {
  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .job_title {
      font-weight: 500 !important;
      color: #212529 !important;
    }
  }

  .view-more-jobs-container-details {
    .cancel-button {
      margin-top: 5px !important;
    }
  }

  //************* JOB LIST DETAILS ***************//
  .job-list-experience-and-location {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    .job-list-experience {
      width: 49%;
      margin-right: 10px;
      .details-div {
        .details-span {
          display: block;
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .job-list-details {
      width: 50%;
      .details-div {
        .details-span {
          display: block;
          width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .details-span-full-width {
      width: 100% !important;
    }
  }

  &__header {
    padding: 10px 15px;
    border-bottom: 1px solid #c0c0c0;

    &__title {
      padding-bottom: 10px;
      .job_title {
        font-size: 15px;
        font-family: "Roboto";
        font-weight: 500;
        color: #015376;
      }
    }
    &__action {
      display: flex;
      align-items: center;
      .apply-button-div {
        display: inline-block;
      }
      .star-div {
        margin-right: 5px;
        display: inline-block;
      }
    }
  }
  &__sub-header {
    margin-top: 10px;
    .company_name {
      display: block;
      font-size: 16px;
      color: #4f4f4f;
      font-family: "Roboto";
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  &__job-deatils {
    padding: 15px;
    display: flex;
  }
  &__description {
    padding: 20px 15px;
  }

  .details-heding {
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
    text-transform: capitalize;
  }
  .skills-div {
    display: flex;
    padding-left: 15px;
    flex-direction: column;
    .jobDetails-skills {
      margin-left: 20px;
    }
  }
  .details-span {
    font-size: 13px;
    color: #727272;
    font-weight: 400;
    text-transform: capitalize;
  }
  .details-div {
    display: flex;
    align-items: center;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
  .icomo-icons {
    font-size: 14px;
    color: #727272;
    font-weight: 400;
    margin-right: 5px;
  }
  .list-keyskill {
    margin-bottom: 5px;
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 500;
    text-transform: capitalize;
  }

  .description-text {
    font-size: 14px;
    color: #727272;
    font-weight: 400;
  }
  .star-icomo {
    color: #015376;
  }

  &__footer {
    padding: 5px 15px 10px;
    display: flex;
    .chatbot-action-button-cancel {
      margin-right: 7px;
    }
  }
}

.scrollable-job-list {
  width: 235px;
  overflow: hidden;
  padding: 10px 15px;
  box-sizing: border-box;
  @media screen and (max-width: 400px) {
    width: 200px;
    padding: 10px;
  }
}

.chatbot-action-button {
  text-align: center;
  padding: 4px 15px;
  text-transform: capitalize;
  background-color: #015376;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
}

.chatbot-action-button-cancel {
  margin-right: 5px;
  background-color: #fff;
  color: #015376;
  border: 1px solid #015376;
}
