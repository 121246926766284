@import 'App/shared/style/_variables.scss';
@import 'App/shared/style/_mixins.scss';

.position-edit {
    padding: 0 15px;

    @media (max-width: 768px) {
        width: 92%;
        padding: 0;
        margin: 0 auto;
    }
}