.calendar-container {
  position: relative;
  .calendar-icon {
    width: 100%;
    font-size: 22px;
    color: #015376;
    text-align: right;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
    right: 3px;
    &:before {
      position: absolute;
      top: -2.5px;
      right: 0px;
    }
  }
  .react-calendar {
    position: absolute;
    bottom: 38px;
    z-index: 1;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  }
  button {
    padding: 2px;
    color: #000;
    font-size: 12px;
    border-radius: 0;
    background: #fff;
    &:disabled {
      color: gray;
    }
  }
  abbr {
    font-size: 12px;
    text-decoration: none;
  }

  .react-calendar__navigation {
    background: #015376;
    button {
      color: #fff;
      background: #015376;
      &:disabled {
        color: grey;
      }
    }
    &__arrow {
      font-size: 25px;
    }
  }
  .react-calendar__month-view__weekdays {
    background: gainsboro;
    &__weekday {
      padding: 5px;
      text-align: center;
    }
  }
  .highlight-date {
    background: rgb(214, 229, 249);
  }
  .calendar-input {
    border-bottom: 0 !important;
    &::placeholder {
      color: #000 !important;
    }
  }
}
.sulross-calendar {
  .react-calendar__navigation,
  .react-calendar__navigation button {
    background: #b71632;
  }
  .highlight-date {
    background: #fcebee;
  }
}
