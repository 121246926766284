@import "../../../shared/style/variables";
@import "../../../shared/style/_mixins.scss";

.location-search-input {
  width: 100%;
  height: 25px;
  padding: 0px;
  box-shadow: none;
  font-size: 15px;
  border: none;
  height: 25px;
  text-overflow: ellipsis;
  line-height: normal !important;
  border-bottom: 1px solid #bdd6ee;

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid #bdd6ee;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &::placeholder {
    @include fontFamily("Roboto", 15px, normal, 400);
    color: #808080;
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    color: #808080;
    @include fontFamily("Roboto", 15px, normal, 400);
  }

  &::-ms-input-placeholder {
    color: #808080;
    @include fontFamily("Roboto", 15px, normal, 400);
  }

  &::-ms-input-placeholder {
    color: #808080;
    @include fontFamily("Roboto", 15px, normal, 400);
  }
}
.open-job-list-up {
  bottom: 35px;
  margin-top: 0px;
  max-height: 150px !important;
  overflow-y: scroll;
}
.set-postion {
  position: relative !important;
}
.suggestions-container {
  width: 100% !important;
  position: absolute;
  padding: 0;
  z-index: 5;
  border-top: 0;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #d2d2d2;
}
.suggestion {
  padding: 5px 5px 5px 35px;
  height: 35px;
  line-height: 26px;
  border-top: 1px solid #d9d9d9;
  font-size: 14px;
  text-align: left;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 100;
}
.autocomplete-icon {
  display: block;
  position: absolute;
  top: 7px;
  bottom: 0;
  left: 8px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
}
.autocomplete-icon.icon-localities {
  background-image: url(https://images.woosmap.com/icons/locality.svg);
}
.suggestion:hover .autocomplete-icon.icon-localities {
  background-image: url(https://images.woosmap.com/icons/locality-selected.svg);
}
.autocomplete-item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.suggestions-container::after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 18px;
  box-sizing: border-box;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
}
.location-icon {
  position: absolute;
  top: 8px;
  left: 0;
}
