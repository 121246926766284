.search-input-container{
    width: 77% ;
    background-color: rgb(214, 229, 249);
    padding: 10px 15px 10px 10px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 10px;
    &__searchbox{
        border-radius: 50px;
        
        height: 40px !important;
        margin-bottom: 0 !important;
        padding: 10px 25px !important;
        color: #000000 !important;
        font-family: "Roboto" !important;
    }
}
.scrollable-list-details{

    max-height: 175px;
    overflow: auto;
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-transform: capitalize;
    background-color: #fff;
    border-radius: 20px;
}
.list-value{
    list-style: none;
    padding: 0px 15px 0 23px;
    &:not(:last-child){
        border-bottom: 1px solid lightgray;
        padding-bottom: 5px;
    }
    &:not(:first-child){
        padding-top: 5px;
    }
    &:last-child{
        padding-bottom: 10px;
    }
    &:first-child{
        padding-top: 10px;
    }
}