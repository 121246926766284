.academic-report {
    margin-bottom: 15px;
    .report-header {
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        padding: 10px 15px;
    }
    .report-details {
        padding: 0px 15px 15px; 
        .details-section {
            font-size: 14px;
            padding: 15px 5px 15px 0px;
            border-bottom: 1px solid lightgray;

            div:first-child{
                font-weight: 500;
            }

            div:last-child{
                color: #4B4B4B;
            }
        }

        .details-section:last-child{
            border-bottom: none;
        }
    }

   

}
